/**********************************************
*
* Link styles
*
***********************************************/

/***** default, paragraph links *****/
a {
    @extend p;
    color: $color-primary;
    font-size: 14px;
    cursor: pointer;
    overflow: hidden;
    text-decoration: $link-base-text-decoration;
    transition: all 0.25s ease;
    @include media-breakpoint-down(xs) {
        font-size: 12px;
    }
    &:hover {
        color: $link-base-hover-color;
        text-decoration: none;
    }
    &.disabled {
        pointer-events: none;
        color: $button-disabled-bg !important;
        &:hover {
            color: $button-disabled-bg !important;
            text-decoration: none;
        }
    }
}

/***** top level naviation link *****/
@mixin nav-link() {
    @include h5-mobile;
    text-decoration: $link-nav-text-decoration;
    text-transform: $link-nav-text-transform;
    @include respond(desktop) {
        @include h6-desktop;
    }
}

/***** top level naviation link *****/
@mixin refinement-link() {
    @include p-mobile;
    text-decoration: $link-refinement-text-decoration;
    @include respond(large-up) {
        @include p-desktop;
    }
    &:hover {
        text-decoration: $link-refinement-text-decoration;
        color: $janie-black;
    }
}

/***** footer links, quickview link; info link *****/
@mixin small-link() {
    @include info;
}

.small-link {
    @include small-link();
    text-decoration: $link-small-text-decoration;
    &:hover {
        color: $link-small-color-hover;
        text-decoration: $link-small-text-decoration-hover;
    }
}

/***** breadcrumb links; mobile home is an icon *****/
@mixin breadcrumb-link() {
    @include alt-p;
    color: $link-breadcrumb-color;
    text-decoration: $link-breadcrumb-text-decoration;
    &:hover {
        color: $link-breadcrumb-color-hover;
        text-decoration: $link-breadcrumb-hover-text-decoration;
    }
}

/***** CTAs under promos + images *****/
@mixin cta-link() {
    @include h3-mobile;
    text-decoration: $link-cta-text-decoration;
    text-transform: $link-cta-text-transform;
    cursor: pointer;
    &::after {
        content: $icon-arrow-small;
        display: inline-block;
        margin-left: rem(5px);
        width: rem(13px);
        height: rem(13px);
        position: relative;
        top: rem(1px);
    }
    &:hover {
        text-decoration: $link-cta-text-decoration-hover;
    }
    @include respond(large-up) {
        @include h5-desktop;
        &::after {
            width: rem(18px);
            height: rem(18px);
            position: relative;
            top: rem(3px);
        }
    }
}

@mixin cta-link-alt() {
    @include h3;
    text-decoration: $link-cta-text-decoration;
    text-transform: $link-cta-alt-text-transform;
    cursor: pointer;
    &::after {
        content: $icon-arrow-small;
        display: inline-block;
        margin-left: rem(5px);
        width: rem(13px);
        height: rem(13px);
        position: relative;
        top: rem(1px);
    }
    &:hover {
        text-decoration: $link-cta-text-decoration-hover;
    }
    @include respond(large-up) {
        text-transform: $link-cta-alt-text-transform;
        &::after {
            width: rem($link-cta-alt-icon-width-desktop);
            height: rem($link-cta-alt-icon-height-desktop);
            position: relative;
            top: rem($link-cta-alt-icon-top-position-desktop);
        }
    }
}


.cta-link {
    @include cta-link();
}

.cta-link-alt {
    @include cta-link-alt();
}

/***** header utility links *****/
@mixin utility-link() {
    @include h7;
    text-decoration: $link-utility-text-decoration;
    span:hover {
        color: inherit;
        text-decoration: $link-utility-text-decoration-hover;
    }
}

.utility-link {
    @include utility-link();
}

.all-cats {
    &::after {
        display: table;
        content: "";
        clear: both;
    }

    .single-category {
        width: 50%;
        &:nth-child(odd) {
            float: left;
        }
        &:nth-child(even) {
            float: right;
        }
    }
}
.button-text {
    color: #63666a;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
    background: transparent;
    border-width: 0;
    padding: 0;
    margin: 0;
    text-decoration: none;
    text-transform: none
}

.button-text:focus,.button-text:hover {
    color: #1b375d;
    background-color: transparent;
    text-decoration: none
}
@media only screen and (min-width: 768px) {
    .tabbedContent .tabs li {
        font-size:.875rem;
        line-height: 20px;
        line-height: 1.25rem;
        letter-spacing: .025em
    }
}

.tabbedContent .tabs li:after {
    content: "";
    border-right: 1px solid #e1e1e1;
    font-size: 8.5px;
    margin-left: .625rem;
    margin-right: .625rem
}

@media only screen and (min-width: 768px) {
    .tabbedContent .tabs li:after {
        font-size:10px
    }
}

.tabbedContent .tabs li:last-of-type:after {
    border-color: transparent;
    margin: 0
}
