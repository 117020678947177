.amplienceContent .amp-tabbed-module {
    max-width: $max-content-breakpoint;
    margin: 0 auto;
    .tabs-header {
        text-align: center;
    }
    .tabs {
        text-align: center;
        padding: 0;
    }
    .cta-container {
        display: inline-block;
        text-align: center;
        width: 49%;
        margin-top: rem(25px);
        @include respond(small-down) {
            margin-top: rem(25px);
        }
    }
    li {
        display: inline-block;
        list-style: none;
        border-right: $tab-horizontal-divider;
        margin: $tab-horizontal-padding-desktop 0 $tab-horizontal-padding-desktop $tab-horizontal-padding-desktop;
        padding-right: $tab-horizontal-padding-desktop;
        cursor: pointer;
        @include respond(small-down) {
            margin: $tab-horizontal-padding-mobile 0 $tab-horizontal-padding-mobile $tab-horizontal-padding-mobile;
            padding-right: $tab-horizontal-padding-mobile;
        }
        &:hover {
            color: $link-small-color-hover;
            border-bottom: none;
        }
        &.active {
            .subtext {
                @include accent-border;
            }
        }
        .subtext {
            @include info;
        }
    }
    li:first-child {
        margin-left: 0;
    }
    li:last-child {
        border-right: none;
        padding-right: 0;
    }
}
