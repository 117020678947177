//------------------------------------
//  MIXINS
//------------------------------------

// A useful mixin to output a list of CSS styles passed in as a map
//
// Example:
//
//    //set a map variable
//    $primary-nav: (
//      padding-top: .2em,
//      margin-top: .2em,
//      line-height: 1.3,
//    );
//
//    //now output with the mixin
//    @include output-styles($primary-nav);
//
// Compiles to:
//
//    //Outputted CSS:
//    .primary-nav {
//      padding-top: .2em;
//      margin-top: .2em;
//      line-height: 1.3;
//    }
//
@mixin output-styles($map) {
    @each $property, $value in $map {
        #{$property}: $value;
    }
}

//
// Generated a media query based on the given option
// Possible options:
//      print - styles only apply to printing
//      small-down - styles only apply to viewports smaller than 768px
//      large-down - styles only apply to viewports smaller than 961px
//      large - styles only apply to viewports between 768px and 960px
//      large-up - styles only apply to viewports 768px and above
//      desktop - styles only apply to viewports 961px and above
//      [min-width] - number value (with units) is applied as the min-width for a custom media query
//      [min-width], [max-width] - values (with units) are applied as min-width & max-width for a custom mediaquery

$i: unquote("!important");

@mixin respond($media, $max: null) {
    @if $media == "print" {
        @media only print {
            @content;
        }
    } @else if $media == "small-down" {
        @media only screen and (max-width: $large-breakpoint - 1) {
            @content;
        }
    } @else if $media == "retina" {
        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            @content;
        }
    } @else if $media == "large-down" {
        @media only screen and (max-width: $desktop-breakpoint - 1) {
            @content;
        }
    } @else if $media == "large" {
        @media only screen and (min-width: $large-breakpoint) and (max-width: $desktop-breakpoint - 1) {
            @content;
        }
    } @else if $media == "large-up" {
        @media only screen and (min-width: $large-breakpoint) {
            @content;
        }
    } @else if $media == "desktop" {
        @media only screen and (min-width: $desktop-breakpoint) {
            @content;
        }
    } @else if type-of($media) == number and $max == null {
        @media only screen and (min-width: $media) {
            @content;
        }
    } @else if type-of($media) == number and type-of($max) == number {
        @media only screen and (min-width: $media) and (max-width: $max - 1) {
            @content;
        }
    }
}

@mixin gradient($start-color, $end-color, $orientation) {
    background: $start-color;
    @if $orientation == vertical {
        // vertical
        background: linear-gradient(to bottom, $start-color 0%, $end-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=0 );
    } @else if $orientation == horizontal {
        // horizontal
        background: linear-gradient(to right, $start-color 0%, $end-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
    } @else {
        // radial
        background: radial-gradient(ellipse at center, $start-color 0%, $end-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
    }
}

// Font Sizer
// ex: @include font-size(14px);  //sets a font size in rems with a fallback in pxs
// ex2: @include font-size(14px, $medium-breakpoint);  //sets a scaling font size in vw's with a fixed fallback in pxs

@mixin font-size($size, $breakpoint: "") {
    @if $breakpoint == "" {
        font-size: $size;
        font-size: rem($size);
    } @else {
        // Get a font size in VWs that will match the given pixel font-size in the given viewport.
        font-size: $size;
        font-size: calculateVw($size, $breakpoint);
    }
}

// Line Height Sizer
// ex:  @include line-height(14px);  //sets a font size in rems with a fallback in pxs
// ex2: @include line-height(14px, $medium-breakpoint);  //sets a scaling line-height value in vw's with a fixed fallback in pxs

@mixin line-height($size, $breakpoint: "") {
    @if $breakpoint == "" {
        line-height: $size;
        line-height: rem($size);
    } @else {
        // Get a font size in VWs that will match the given pixel line-height in the given viewport.
        line-height: $size;
        line-height: calculateVw($size, $breakpoint);
    }
}


// Inner page content width
//---------------------------------------

@mixin content-width() {
    box-sizing: border-box;
    margin: auto;
    width: calc(100% - #{$content-width-gutters*2});
    max-width: $max-content-breakpoint;
    /* To remove fluidity in viewports, uncomment this block.
    @include respond(medium-up) {
        width: $medium-breakpoint - $content-width-gutters*2;
    }
    @include respond(large-up) {
        width: $large-breakpoint - $content-width-gutters*2;
    }
    @include respond(desktop) {
        width: $desktop-breakpoint - $content-width-gutters*2;
    }
    */
}

// Apply full-bleed width to a content-width page by including this mixin
@mixin override-content-width() {
    width: 100%;
    max-width: 100%;
}

//Remove the default gutters in a content-width page by including this mixin
@mixin override-content-width-gutters() {
    width: 100%;
}

// Clearfix
//---------------------------------------

@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}


// Visually Hidden Elements
//---------------------------------------

@mixin visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

// Menu - Base Styles
//---------------------------------------

@mixin menu() {
    float: left;
    margin: 0;
    padding: 0;
    li {
        float: left;
        list-style: none outside none !important;
    }
}

// Full-Bleed (using VW's)
//---------------------------------------

@mixin full-bleed-width() {
    width: 100vw;
    max-width: 100vw;
    margin-left: calc((100vw - 100%) / -2);
}

// can be applied to elements that already receive the full-bleed styling to override within a particular viewport
@mixin override-full-bleed() {
    width: auto;
    max-width: 0;
    margin-left: initial;
}
