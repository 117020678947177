.heroSlider {
    max-width: 100vw;
    &.slick-initialized {
        display: block;
    }
    .slider-prev,
    .slider-next {
        opacity: 0;
        @include respond(small-down) {
            display: none !important;
        }
    }
    @include respond(large-up) {
        .slider-prev {
            display: block;
            left: 15px;
        }
        .slider-next {
            display: block;
            right: 15px;
        }
    }
    &:hover {
        @include respond(large-up) {
            .slider-prev,
            .slider-next {
                opacity: 1;
            }
        }
    }
    &[data-arrows="false"] {
        .slider-prev,
        .slider-next {
            display: none !important;
        }
    }
}

.heroSlide {
    position: relative;
    width: 100%;
    img {
        width: 100%;
        height: auto;
    }
    &.image {
        .slick-dots {
            top: -45px;
        }
    }
    // video container
    .amp-video-skin {
        width: 100%;
    }

    // text-block type slide styles
    .textContainer {
        box-sizing: border-box;
        padding: rem(20px) rem(15px);
        text-align: center;
        width: 100%;
        @include respond(small-down) {
            &.inset {
                box-sizing: border-box;
                position: absolute;
                bottom: 3%;
                width: auto;
                margin: 0 rem(10px) rem(30px);
            }
        }
        @include respond(large-up) {
            padding: rem(40px) rem(30px);
            &.left,
            &.right,
            &.center {
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
            }
            &.left {
                left: 25%;
                @include respond(large-up) {
                    left: calc(25% + 70px); // accomodate button
                }
            }
            &.right {
                right: -6%;
            }
            &.center {
                left: 50%;
            }
            &.bar {
                .text-content-wrapper {
                    overflow: hidden;
                    margin: 0 auto;
                    width: auto;
                    display: inline-block;
                }
                .heroHeadline {
                    display: inline;
                    padding-right: 2%;
                }
                .heroParagraph {
                    display: none;
                }
                .heroCTA {
                    display: inline;
                    position: relative;
                    top: 8px;
                }
                .button {
                    margin: 0;
                }
                .groupCTA {
                    display: none;
                }
            }
            &.square {
                width: 27%;
                min-width: rem(280px);
                @include respond(large-up) {
                    min-width: rem(350px);
                }
            }
            &.rectangle {
                top: initial;
                bottom: -20%;
                @include respond(large-up) {
                    width: 55%;
                    bottom: -25%;
                }
                @include respond(desktop) {
                    bottom: -7%;
                }
            }
        }
        .heroParagraph p {
            line-height: 2rem;
        }
    }
    .slideCaption {
        float: right;
        @include info;
        margin: rem(5px);
    }
}
