
.country-selector {
    .btn {
        span {
            &:hover {
                text-decoration: underline;
            }
        }
    }
    border-bottom: 5px solid darken($medium-grey, 10%);
    color: $dark-grey;
    width: 100%;
    @include respond(large-up) {
        border-bottom: none;
        padding: 0;
        //position: absolute;
        //top: -1rem;
        //right: .2rem;
        z-index: 30;
        width: auto;
    }
    .selector {
        display: none;
        margin-top: .5rem;
        padding-left: 2rem;
        background-color: $white;
        border-radius: 3px;
        box-shadow: -1px 2px 4px 0 rgba($black, 0.3);
        margin-top: 1rem;
        padding: 1rem;
        .shipto-text {
            display: block;
            text-align: center;
            padding-bottom: 1rem;
        }
        button.saveandcontinue {
            text-align: center;
            display: block;
            margin: rem(0) auto;
            font-size: 0.8rem;
            text-decoration: underline;
            font-weight: normal;
            cursor: pointer;
        }
        &.active {
            display: block;
            position: absolute;
            width: 35%;
            z-index: 10;
            @media only screen and (max-width: 1024px) {
                width: 40%;
            }
        }
        .closer {
            width: 100%;
            display: inline-block;
            span {
                background-image: url("../images/svg-icons/Close_Blue.svg");
                background-position: left top;
                height: 12px;
                width: 12px;
                display: inline-block;
                float: right;
                margin-bottom: 5px;
                cursor: pointer;
            }
        }
    }
    .current-country {
        cursor: pointer;
        padding: .8em 1rem;
        span.shipto {
            //color: $dark-grey;
            font-size: 0.75rem;
            font-weight: bold;
            letter-spacing: 0.05em;
            &:hover {
                text-decoration: underline;
            }
        }
        &::after {
            content: "";
            display: inline-block;
            border-right: $tab-horizontal-divider;
            font-size: $tab-horizontal-divider-height-mobile;
            margin-left: rem(12px);
            margin-right: rem(12px);
            margin-top: 2px;
            height: 10px;
            vertical-align: middle;
            display: none;
            @media screen and (min-width: 1024px) {
                display: inline-block;
            }
        }
        &.selector-active {
            .selector-icon {
                transform: rotate(270deg);
            }
        }
        @include respond(large-up) {
            padding: 0;
            &.selector-active {
                border-bottom: none;
                padding-bottom: 0;
                // prevent the text from shifting left to accommmodate the selector width
                //position: absolute;
                //right: 0;
            }
        }

        .selector-icon {
            margin-right: .3rem;
            @include respond(large-up) {
                float: right;
                margin-right: 0;
                margin-left: .3rem;
                // display as angle-down icon for desktop
                transform: rotate(90deg);
            }
        }
    }
    .continent-name {
        margin-top: 0;
        margin-bottom: .5rem;
        @include respond(large-up) {
            color: $red;
        }
    }
    .country {
        margin-bottom: .5rem;
    }
    .locale {
        border-right: 1px solid $medium-grey;
        display: inline-block;
        padding: 0 .3rem;
        vertical-align: middle;
        &:first-of-type {
            padding-left: 0;
        }
        &:last-of-type {
            border-right: none;
            padding-right: 0;
        }
    }
    .country-icon {
        vertical-align: middle;
        img {
            border: 1px solid $medium-grey;
            max-width: 18px;
            max-height: 18px;
        }
    }
}

// Country Picker styles

.psuedo-counrty-overlay {
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    top: 0;
}
// mobile design
.country-mobile {
    .li {
        &.country-selector {
            border: none;
            height: 0;
        }
    }
    .country-selector {
        border-bottom: 0;
        height: 50px;
        width: 100%;
        position: relative;
        -webkit-appearance: none;
        line-height: normal;
        font-size: 12px;
        opacity: 1;
        background: $white;
        border: 1px solid $medium-grey;
        height: 2.8125rem;
        border-radius: 23px;
        width: 100%;
        padding: 0 0.9375rem 0 1.25rem;
        box-sizing: border-box;
        color: $dark-grey;
        letter-spacing: .07rem;
        font-family: "Avenir-Black", Arial, sans-serif;
        background: url("../images/svg-icons/Arrow_Down_small.svg");
        background-size: 14px;
        background-repeat: no-repeat;
        background-position: 95% 50%;
        .current-country {
            padding: 0;
            margin: 1px 0 0 15px;
            float: left;
            display: inline-block;
        }
        .selector {
            &.active {
                top: 34px;
                background: $white;
                width: 93%;
                max-width: 375px;
            }
        }
        span.ui-selectmenu-button {
            margin: 0 0 10px 0;
            width: 100% !important;
            display: none;
        }
    }
}
//Shipping page changes.
#country-selector-modal {
    display: none;
}
.ui-dialog {
    &.countryChangeDialog {
        .ui-dialog-title {
            font-size: 1.375rem;
            line-height: 2.125rem;
            letter-spacing: 0.02em;
            margin-top: 10px;
            font-weight: bold;
        }
        .ui-dialog-content {
            padding: 1% 9% 0 9%;
            @include respond(small-down) {
                padding: 1% 4% 0 4%;
            }
        }
    }
    @include respond(small-down) {
        .li {
            &.country-selector {
                .selector {
                    &.active {
                        .country-selector {
                            border-bottom: 0;
                            position: relative;
                            -webkit-appearance: none;
                            line-height: normal;
                            font-size: 12px;
                            opacity: 1;
                            border: 1px solid #e1e1e1;
                            height: 2.8125rem;
                            border-radius: 23px;
                            width: 100%;
                            padding: 0 0.9375rem 0 1.25rem;
                            box-sizing: border-box;
                            color: $dark-grey;
                            letter-spacing: .07rem;
                            font-family: "Avenir-Black", Arial, sans-serif;
                            background: url("../images/svg-icons/Arrow_Down_small.svg") no-repeat 95% 50%;
                            background-size: 14px;
                        }
                        .ui-selectmenu-button {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
.countryChangeDialog {
    .country-selector {
        .selector {
            position: static;
            width: 100%;
            box-shadow: none;
            margin: 0;
            box-sizing: border-box;
            .closer {
                display: none;
            }
        }
        .current-country {
            pointer-events: none;
        }
        span {
            .ui-selectmenu-button {
                width: 93% !important;
            }
        }
    }
    .current-country::after {
        display: none;
    }
    select {
        border: 0;
        width: 1px;
    }
}
.focus-hide-safari {
    display: none;
}
.change-country-checkout {
    font-size: 14px;
    cursor: pointer;
    display: none;
}
.checkout-shipping {
    .change-country-checkout {
        display: inline-block;
        text-decoration: underline;
        @include respond(large-down) {
            text-align: right;
            margin-top: 10px;
        }
    }
}

//hosted page link
#flow-return-link {
    margin-bottom: 10px;
    display: inline-block;
    width: auto;
    font-size: 14px;
}
