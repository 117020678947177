// Import the styles generated from the SVG icons
//@import "../../../../org_gym/cartridge/scss/default/compiled/svg";
@import "common/svg";

// Styles apply to svg icons implemented via the svghelper module
.icon {
    display: inline-block;
    vertical-align: middle;
}
.Accross_devices {
    stroke: $dark-grey;
    stroke-width: 2;
    stroke-miterlimit: 10;
}

.Telephone {
    fill: $dark-grey;
}

.Added_To_Bag_Checkmark {
    fill: none;
    stroke-width: 6px;
    stroke: $icon-color-dark;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.Account_Who_Im_Shopping_For {
    fill: $icon-color-dark;
    color: $dark-grey;
}

.Account_Payments {
    color: $dark-grey;
}

.Account_Privacy {
    color: $dark-grey;
    stroke: none;
}

.Account_Comm_Pref,
.envelope_small {
    fill: none;
    stroke: $dark-grey;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.Footer_Shipping_Services {
    fill: none;
    color: $dark-grey;
    stroke-miterlimit: 10;
}

.Checkbox_Checked {
    color: $navy;
}

.Checkout_Gift {
    fill: $white;
    stroke: $dark-grey;
}

.success_check,
.check_circle {
    fill: $navy;
    stroke: $white;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.Radio_Selected {
    stroke-miterlimit: 10;
    color: $navy;
}

.Footer_Size {
    fill: none;
    color: $dark-grey;
    stroke-width: 1px;
    stroke-miterlimit: 10;
}

.empty-cart-devices {
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: $dark-grey;
}

.Footer_gift_cards {
    fill: #a0a9b0;
    color: $navy;
}

.Footer_gift_services {
    fill: none;
    color: $navy;

}

.Floating_widget_free_shipping {
    fill: none;
    color: $dark-grey;
    stroke-width: 2.3553;
    stroke-miterlimit: 10;
    width: rem(100px);
    height: rem(50px);
}

.Footer_Refer_A_Friend {
    fill: $white;
    stroke: $dark-grey;
    stroke-width: 1.5998;
    stroke-miterlimit: 10;
}
.nav_promo_strip_shipping_icon {
    fill: $white;
    stroke: $dark-grey;
    stroke-width: 2.0703;
    stroke-miterlimit: 10;
}
.Account_Order_History {
    fill: none;
    stroke: $dark-grey;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}
.Alert {
    fill: $color-error;
    color: $color-error-bg;
}

.icon_error {
    width: rem(24px);
    height: rem(24px);
}

.Arrow {
    fill: none;
    stroke-width: 2px;
    stroke: $dark-grey;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    width: 20px; //19px
    height: 20px; //33px
    @include respond(large-up) {
        width: 30px;
        height: 30px;
        stroke-width: 4px;
    }
    &:hover {
        stroke: $icon-color-dark;
    }
    &.down-arrow {
        transform: rotate(90deg);
    }
    &.up-arrow {
        transform: rotate(-90deg);
    }
    &.left-arrow {
        transform: rotate(180deg);
    }
    &.medium {
        width: 15px; //13px;
        height: 15px; //21px;
        @include respond(large-up) {
            width: 20px;
            height: 20px;
        }
    }
    &.small {
        width: 10px; //10px;
        height: 10px; //17px;
    }
}
.Account_Addresses {
    fill: none;
    stroke: $dark-grey;
    stroke-width: 5;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.Arrow_small {
    width: 10px; //10px;
    height: 10px; //17px;
    &.down-arrow {
        transform: rotate(90deg);
    }
    &.up-arrow {
        transform: rotate(-90deg);
    }
    &.left-arrow {
        transform: rotate(180deg);
    }
}

.Arrow_White {
    fill: none;
    stroke-width: 2px;
    stroke: $white;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    width: 16px; //36px
    height: 25.5px; //51px
    @include respond(large-up) {
        width: 18px;
        height: 29px;
        stroke-width: 4px;
    }
    &:hover {
        color: $icon-color-dark;
    }
    &.medium {
        width: 6.5px; //13px;
        height: 10.5px; //21px;
        @include respond(large-up) {
            width: 13px;
            height: 21px;
        }
    }
    &.small {
        width: 10px; //10px;
        height: 10px; //17px;
        stroke-width: 5px;
    }
}

.Account_Coupons {
    fill: none;
    stroke: $dark-grey;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.Back_to_top {
    fill: $white;
    color: $white;
    stroke-width: 0;
    width: 36.5px; //73px;
    height: 36.5px; //73px;
    box-shadow: 0 1px 1px 0 $medium-grey;
    border-radius: 50%;
    @include respond(large-up) {
        width: 50px;
        height: 50px;
    }
}

.Close_Circle {
    fill: $white;
    color: $icon-color-dark;
    width: 30px; //60px;
    height: 30px; //60px;
}

.Close {
    fill: $icon-color-dark;
    width: 14.5px; //29px;
    height: 14.5px; // 29px;
    @include respond (large-up) {
        width: 16px;
        height: 16px;
    }
    &.small {
        width: 11px; //22px;
        height: 10px; //20px;
        @include respond(large-up) {
            width: 12px;
            height: 12px;
        }
    }
}


.Favorites {
    fill: $white;
    stroke: $dark-grey;
    stroke-miterlimit: 10;
    stroke-width: 2px;
    &.filled,
    &:hover {
        fill: $navy;
        stroke: $navy;
    }
}

.Error {
    fill: $red;
    color: $white;
}

.Hamburger_Menu {
    fill: $icon-color-dark;
    width: 18.5px; //37px
    height: 18px; //32px
    position: relative;
    top: -1px;

    @include media-breakpoint-up(sm) {
        top: -2px;
    }
}

.Heart {
    fill: none;
    stroke: $dark-grey;
    stroke-width: 2px;
    stroke-miterlimit: 10;
    width: rem(15px);
    height: rem(14px);
    top: -2px;
    position: relative;
    margin-right: rem(2px);
}

.Home {
    fill: none;
    stroke: $dark-grey;
    stroke-width: 5px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.Instagram_Icon {
    color: $navy;
}

.link {
    width: 40px;
    height: 36px;
    position: relative;
    color: $dark-grey;
    @include respond(large-up) {
        top: -7px;
    }
}

.Low_Stock {
    color: $color-error;
}

.Monogram {
    stroke: $icon-color-light;
    fill: none;
}

.Not_Available {
    color: $red;
}

.Email {
    width: rem(60px);
    height: rem(45px);
}

.Play_Button {
    color: $white;
    fill: $navy;
    width: 55px; //110px;
    height: 55px; //110px;
    @include respond(large-up) {
        width: 88px;
        height: 88px;
    }
    &.small {
        width: 15.5px; //31px;
        height: 15.5px; //31px;
        @include respond(large-up) {
            width: 31px;
            height: 31px;
        }
    }
}

.PDP_Shirt {
    fill: $white;
    stroke: $dark-grey;
    stroke-width: 1.8511;
    stroke-miterlimit: 10;
}

.PDP_Button {
    stroke: $dark-grey;
    color:  $dark-grey;
    stroke-miterlimit: 10;
}

.PDP_Details {
    fill: $white;
    stroke: $dark-grey;
    stroke-width: 2px;
    stroke-miterlimit: 10;
}


.Question_Mark {
    color: $white;
    fill: $navy;
}

.Review_Pencil {
    fill: none;
    stroke: $dark-grey;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.Review_Star_Full,
.star-full svg {
    fill: $icon-color-dark;
    width: 10px; //20px;
    height: 10px; //20px;
    @include respond(large-up) {
        width: 13px;
        height: 13px;
    }
    &.empty {
        fill: $disabled-grey;
    }
}

.star-empty svg {
    fill: $disabled-grey;
    width: 10px; //20px;
    height: 10px; //20px;
    @include respond(large-up) {
        width: 13px;
        height: 13px;
    }
}


.Review_Star_Half,
.star-half svg {
    fill: $icon-color-dark;
    color: $disabled-grey;
    width: 10px; //20px;
    height: 10px; //20px;
    @include respond(large-up) {
        width: 13px;
        height: 13px;
    }
}

.Search {
    fill: none;
    stroke: $dark-grey;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.Size_Chart {
    fill: none;
    stroke: $dark-grey;
    stroke-width: 1.6252;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}


.Footer_size_chart {
    color: $dark-grey;
    stroke-width: 2.4988;
    stroke-miterlimit: 10;
}

.Social_Facebook,
.Social_Instagram,
.Social_Pinterest,
.Social_Snapchat {
    color: $dark-grey;
    fill: $white;
}

.Subcat_Grid_Icon1,
.Subcat_Grid_Icon2 {
    width: 23px; //46px;
    height: 26px; //52px;
    fill: $medium-grey;
    &:hover {
        fill: $dark-grey;
    }
}

.Utility_Bag {
    width: 24px;
    height: 24px;
    color: $dark-grey;
    stroke-width: 2px;
    top: -7px !important;
    @include respond($mobile-menu-breakpoint) {
        height: 32px;
        stroke-width: 2px;
        top: -3px !important;
    }
}

.Utility_Login {
    fill: $white;
    stroke: $dark-grey;
    stroke-width: 4px;
    stroke-miterlimit: 10;
    width: rem(13px);
    height: rem(13px);
    top: rem(-2px);
    margin-right: rem(2px);
    top: rem(-2px);
    position: relative;
}
.Account_Name {
    fill: $white;
    stroke: $dark-grey;
    stroke-width: 1.7387;
    stroke-miterlimit: 10;
}

.Utility_Store_Locator {
    fill: $white;
    stroke: $dark-grey;
    width: 18px !important;
    height: 20px !important;
    stroke-width: 3px;
    top: -2px;
    position: relative;
}

.PDP_Pin {
    fill: $white;
    stroke: $dark-grey;
    stroke-width: 2px;
    //width: 17.5px; // 29px
    height: 25.5px; //37px;
}

.pin_small_dark,
.pin_large_dark {
    color: $navy;
    stroke: $navy;
    fill: $navy;
}

.pin_small_light,
.pin_large_light {
    color: $blue;
    stroke: $blue;
    fill: $blue;
}

.Utility_Tracking {
    fill: none;
    stroke: $dark-grey;
    stroke-width: 2px;
    stroke-miterlimit: 10;
    width: rem(18px);
    height: rem(18px);
    position: relative;
    top: -2px;
}
.Utility_Rewards {
    fill: none;
    stroke: $dark-grey;
    stroke-width: 1.6687;
    stroke-miterlimit: 10;
}

.Zoom {
    fill: none;
    stroke: $dark-grey;
    stroke-width: 5px;
    stroke-miterlimit: 10;
}

.Wishlist-Favorites,
.Account_Favorites {
    fill: none;
    stroke: $dark-grey;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.Sale {
    color: $dark-grey;
}
.envelope_small {
    width: 60px;
    height: 40px;
    color: $dark-grey;
}
