// Headings
//---------------------------------------

@mixin h1() {
    font-family: $h1-font;
    @include font-size($h1-font-size-mobile);
    @include line-height($h1-line-height-mobile);
    font-weight: bold; // needed for safari
    letter-spacing: $h1-letter-spacing-mobile;
    text-transform: $h1-text-transform;
    @include respond(large-up) {
        font-size: rem($h1-font-size-desktop);
        @include line-height($h1-line-height-desktop);
        letter-spacing: $h1-letter-spacing-desktop;
    }
}

@mixin h2() {
    font-family: $h2-font;
    @include font-size($h2-font-size-mobile);
    @include line-height($h2-line-height-mobile);
    font-weight: bold; // needed for safari
    letter-spacing: $h2-letter-spacing-mobile;
    text-transform: $h2-text-transform;
    @include respond(large-up) {
        font-size: rem($h2-font-size-desktop);
        @include line-height($h2-line-height-desktop);
        letter-spacing: $h2-letter-spacing-desktop;
    }
}

@mixin h3() {
    font-family: $h3-font;
    @include font-size($h3-font-size-mobile);
    @include line-height($h3-line-height-mobile);
    font-weight: bold; // needed for safari
    letter-spacing: $h3-letter-spacing-mobile;
    text-transform: $h3-text-transform;
    @include respond(large-up) {
        font-size: rem($h3-font-size-desktop);
        @include line-height($h3-line-height-desktop);
        letter-spacing: $h3-letter-spacing-desktop;
    }
}

@mixin h4() {
    font-family: $h4-font;
    @include font-size($h4-font-size-mobile);
    @include line-height($h4-line-height-mobile);
    letter-spacing: $h4-letter-spacing-mobile;
    font-weight: bold; // needed for safari
    text-transform: $h4-text-transform;
    @include respond(large-up) {
        font-size: rem($h4-font-size-desktop);
        @include line-height($h4-line-height-desktop);
        letter-spacing: $h4-letter-spacing-desktop;
    }
}

@mixin h5() {
    font-family: $h5-font;
    @include font-size($h5-font-size-mobile);
    @include line-height($h5-line-height-mobile);
    letter-spacing: $h5-letter-spacing-mobile;
    text-transform: $h5-text-transform;
    font-weight: bold; // needed for safari
    @include respond(large-up) {
        font-size: rem($h5-font-size-desktop);
        @include line-height($h5-line-height-desktop);
        letter-spacing: $h5-letter-spacing-desktop;
    }
}

@mixin h6() {
    font-family: $h6-font;
    @include font-size($h6-font-size-mobile);
    @include line-height($h6-line-height-mobile);
    letter-spacing: $h6-letter-spacing;
    text-transform: $h6-text-transform;
    font-weight: bold; // needed for safari
    @include respond(large-up) {
        font-size: rem($h6-font-size-desktop);
        @include line-height($h6-line-height-desktop);
        letter-spacing: $h6-letter-spacing-desktop;
    }
}

@mixin h7() {
    font-family: $h7-font;
    @include font-size($h7-font-size-mobile);
    @include line-height($h7-line-height-mobile);
    letter-spacing: $h7-letter-spacing;
    font-weight: bold; // needed for safari
    @include respond(large-up) {
        font-size: rem($h7-font-size-desktop);
        @include line-height($h7-line-height-desktop);
    }
}


h1,
.h1 {
    @include h1;
}

h2,
.h2 {
    @include h2;
}

h3,
.h3 {
    @include h3;
}

h4,
.h4 {
    @include h4;
}

h5,
.h5 {
    @include h5;
}

h6,
.h6 {
    @include h6;
}

.h7 {
    @include h7;
}



// Mobile Heading Overrides for mobile footer and header elements
//---------------------------------------

@mixin h1-mobile() {
    font-family: $h1-font;
    @include font-size($h1-font-size-mobile);
    @include line-height($h1-line-height-mobile);
    font-weight: bold; // needed for safari
    letter-spacing: $h1-letter-spacing-mobile;
    text-transform: $h1-text-transform;
}

@mixin h2-mobile() {
    font-family: $h2-font;
    @include font-size($h2-font-size-mobile);
    @include line-height($h2-line-height-mobile);
    font-weight: bold; // needed for safari
    letter-spacing: $h2-letter-spacing-mobile;
    text-transform: $h2-text-transform;
}

@mixin h3-mobile() {
    font-family: $h3-font;
    @include font-size($h3-font-size-mobile);
    @include line-height($h3-line-height-mobile);
    font-weight: bold; // needed for safari
    letter-spacing: $h3-letter-spacing-mobile;
    text-transform: $h3-text-transform;
}

@mixin h4-mobile() {
    font-family: $h4-font;
    @include font-size($h4-font-size-mobile);
    @include line-height($h4-line-height-mobile);
    letter-spacing: $h4-letter-spacing-mobile;
    font-weight: bold; // needed for safari
    text-transform: $h4-text-transform;
}

@mixin h5-mobile() {
    font-family: $h5-font;
    @include font-size($h5-font-size-mobile);
    @include line-height($h5-line-height-mobile);
    letter-spacing: $h5-letter-spacing-mobile;
    text-transform: $h5-text-transform;
    font-weight: bold; // needed for safari
}

@mixin h6-mobile() {
    font-family: $h6-font;
    @include font-size($h6-font-size-mobile);
    @include line-height($h6-line-height-mobile);
    letter-spacing: $h6-letter-spacing;
    text-transform: $h6-text-transform;
    font-weight: bold; // needed for safari
}

@mixin h7-mobile() {
    font-family: $h7-font;
    @include font-size($h7-font-size-mobile);
    @include line-height($h7-line-height-mobile);
    letter-spacing: $h7-letter-spacing;
    font-weight: bold; // needed for safari
}


.h1-mobile {
    @include h1-mobile;
}

.h2-mobile {
    @include h2-mobile;
}

.h3-mobile {
    @include h3-mobile;
}

.h4-mobile {
    @include h4-mobile;
}

.h5-mobile {
    @include h5-mobile;
}

.h6-mobile {
    @include h6-mobile;
}

.h7-mobile {
    @include h7-mobile;
}



// desktop Heading Overrides for desktop footer and header elements
//---------------------------------------

@mixin h1-desktop() {
    font-family: $h1-font;
    @include font-size($h1-font-size-desktop);
    @include line-height($h1-line-height-desktop);
    font-weight: bold; // needed for safari
    letter-spacing: $h1-letter-spacing-desktop;
    text-transform: $h1-text-transform;
}

@mixin h2-desktop() {
    font-family: $h2-font;
    @include font-size($h2-font-size-desktop);
    @include line-height($h2-line-height-desktop);
    font-weight: bold; // needed for safari
    letter-spacing: $h2-letter-spacing-desktop;
    text-transform: $h2-text-transform;
}

@mixin h3-desktop() {
    font-family: $h3-font;
    @include font-size($h3-font-size-desktop);
    @include line-height($h3-line-height-desktop);
    font-weight: bold; // needed for safari
    letter-spacing: $h3-letter-spacing-desktop;
    text-transform: $h3-text-transform;
}

@mixin h4-desktop() {
    font-family: $h4-font;
    @include font-size($h4-font-size-desktop);
    @include line-height($h4-line-height-desktop);
    letter-spacing: $h4-letter-spacing-desktop;
    font-weight: bold; // needed for safari
    text-transform: $h4-text-transform;
}

@mixin h5-desktop() {
    font-family: $h5-font;
    @include font-size($h5-font-size-desktop);
    @include line-height($h5-line-height-desktop);
    letter-spacing: $h5-letter-spacing-desktop;
    text-transform: $h5-text-transform;
    font-weight: bold; // needed for safari
}

@mixin h6-desktop() {
    font-family: $h6-font;
    @include font-size($h6-font-size-desktop);
    @include line-height($h6-line-height-desktop);
    letter-spacing: $h6-letter-spacing;
    text-transform: $h6-text-transform;
    font-weight: bold; // needed for safari
}

@mixin h7-desktop() {
    font-family: $h7-font;
    @include font-size($h7-font-size-desktop);
    @include line-height($h7-line-height-desktop);
    letter-spacing: $h7-letter-spacing;
    font-weight: bold; // needed for safari
}


.h1-desktop {
    @include h1-desktop;
}

.h2-desktop {
    @include h2-desktop;
}

.h3-desktop {
    @include h3-desktop;
}

.h4-desktop {
    @include h4-desktop;
}

.h5-desktop {
    @include h5-desktop;
}

.h6-desktop {
    @include h6-desktop;
}

.h7-desktop {
    @include h7-desktop;
}



// Promo Headings
//---------------------------------------

@mixin promo1() {
    font-family: $promo1-font;
    font-size: rem($promo1-font-size-mobile);
    @include line-height($promo1-line-height-mobile);
    font-weight: normal; // needed for safari
    text-transform: $promo1-text-transform;
    @include respond(large-up) {
        font-size: rem($promo1-font-size-desktop);
        @include line-height($promo1-line-height-desktop);
    }
}

@mixin promo1-mobile() {
    font-family: $promo1-font;
    font-size: rem($promo1-font-size-mobile);
    @include line-height($promo1-line-height-mobile);
    font-weight: normal; // needed for safari
    text-transform: $promo1-text-transform;
}

@mixin promo1-desktop() {
    font-family: $promo1-font;
    font-size: rem($promo1-font-size-desktop);
    @include line-height($promo1-line-height-desktop);
    font-weight: normal; // needed for safari
    text-transform: $promo1-text-transform;
}

@mixin promo2() {
    font-family: $promo2-font;
    font-size: rem($promo2-font-size-mobile);
    @include line-height($promo2-line-height-mobile);
    font-weight: normal; // needed for safari
    text-transform: $promo2-text-transform;
    @include respond(large-up) {
        font-size: rem($promo2-font-size-desktop);
        @include line-height($promo2-line-height-desktop);
    }
}

@mixin promo2-mobile() {
    font-family: $promo2-font;
    font-size: rem($promo2-font-size-mobile);
    @include line-height($promo2-line-height-mobile);
    font-weight: normal; // needed for safari
    text-transform: $promo2-text-transform;
}

@mixin promo2-desktop() {
    font-family: $promo2-font;
    font-size: rem($promo2-font-size-desktop);
    @include line-height($promo2-line-height-desktop);
    font-weight: normal; // needed for safari
    text-transform: $promo2-text-transform;
}

@mixin promo3() {
    font-family: $promo3-font;
    font-size: rem($promo3-font-size-mobile);
    @include line-height($promo3-line-height-mobile);
    font-weight: normal; // needed for safari
    text-transform: $promo3-text-transform;
    @include respond(large-up) {
        font-size: rem($promo3-font-size-desktop);
        @include line-height($promo3-line-height-desktop);
        font-weight: normal; // needed for safari
    }
}

@mixin promo3-mobile() {
    font-family: $promo3-font;
    font-size: rem($promo3-font-size-mobile);
    @include line-height($promo3-line-height-mobile);
    font-weight: normal; // needed for safari
    text-transform: $promo3-text-transform;
}

@mixin promo3-desktop() {
    font-family: $promo3-font;
    font-size: rem($promo3-font-size-desktop);
    @include line-height($promo3-line-height-desktop);
    font-weight: normal; // needed for safari
    text-transform: $promo3-text-transform;
}

@mixin promo4() {
    font-family: $promo4-font;
    font-size: rem($promo4-font-size-mobile);
    @include line-height($promo4-line-height-mobile);
    font-weight: normal; // needed for safari
    text-transform: $promo4-text-transform;
    @include respond(large-up) {
        font-size: rem($promo4-font-size-desktop);
        @include line-height($promo4-line-height-desktop);
    }
}

@mixin promo4-mobile() {
    font-family: $promo4-font;
    font-size: rem($promo4-font-size-mobile);
    @include line-height($promo4-line-height-mobile);
    font-weight: normal; // needed for safari
    text-transform: $promo4-text-transform;
}

@mixin promo4-desktop() {
    font-family: $promo4-font;
    font-size: rem($promo4-font-size-desktop);
    @include line-height($promo4-line-height-desktop);
    font-weight: normal; // needed for safari
    text-transform: $promo4-text-transform;
}

@mixin promo5() {
    font-family: $promo5-font;
    font-size: rem($promo5-font-size-mobile);
    @include line-height($promo5-line-height-mobile);
    font-weight: normal; // needed for safari
    text-transform: $promo5-text-transform;
    @include respond(large-up) {
        font-size: rem($promo5-font-size-desktop);
        @include line-height($promo5-line-height-desktop);
    }
}

@mixin promo5-mobile() {
    font-family: $promo5-font;
    font-size: rem($promo5-font-size-mobile);
    @include line-height($promo5-line-height-mobile);
    font-weight: normal; // needed for safari
    text-transform: $promo5-text-transform;
}

@mixin promo5-desktop() {
    font-family: $promo5-font;
    font-size: rem($promo5-font-size-desktop);
    @include line-height($promo5-line-height-desktop);
    font-weight: normal; // needed for safari
    text-transform: $promo5-text-transform;
}

.promo1 {
    @include promo1;
}

.promo1-mobile {
    @include promo1-mobile;
}

.promo1-desktop {
    @include promo1-desktop;
}

.promo2 {
    @include promo2;
}

.promo2-mobile {
    @include promo2-mobile;
}

.promo2-desktop {
    @include promo2-desktop;
}


.promo3 {
    @include promo3;
}

.promo3-mobile {
    @include promo3-mobile;
}

.promo3-desktop {
    @include promo3-desktop;
}

.promo4 {
    @include promo4;
}

.promo4-mobile {
    @include promo4-mobile;
}

.promo4-desktop {
    @include promo4-desktop;
}

.promo5 {
    @include promo5;
}

.promo5-mobile {
    @include promo5-mobile;
}

.promo5-desktop {
    @include promo5-desktop;
}


// Paragraphs
//---------------------------------------

p {
    font-family: $paragraph-font-family;
    @include font-size($paragraph-font-size-mobile);
    @include line-height($paragraph-line-height-mobile);
    letter-spacing: $paragraph-letter-spacing-mobile;
    font-weight: normal; // needed for safari
    @include respond(large-up) {
        @include font-size($paragraph-font-size-desktop);
        @include line-height($paragraph-line-height-desktop);
        letter-spacing: $paragraph-letter-spacing-desktop;
    }
}

@mixin p {
    font-family: $paragraph-font-family;
    @include font-size($paragraph-font-size-mobile);
    @include line-height($paragraph-line-height-mobile);
    letter-spacing: $paragraph-letter-spacing-mobile;
    font-weight: normal; // needed for safari
    @include respond(large-up) {
        @include font-size($paragraph-font-size-desktop);
        @include line-height($paragraph-line-height-desktop);
        letter-spacing: $paragraph-letter-spacing-desktop;
    }
}

@mixin p-mobile {
    font-family: $paragraph-font-family;
    @include font-size($paragraph-font-size-mobile);
    @include line-height($paragraph-line-height-mobile);
    letter-spacing: $paragraph-letter-spacing-mobile;
    font-weight: normal; // needed for safari
}

@mixin p-desktop {
    font-family: $paragraph-font-family;
    @include font-size($paragraph-font-size-desktop);
    @include line-height($paragraph-line-height-desktop);
    letter-spacing: $paragraph-letter-spacing-desktop;
    font-weight: normal; // needed for safari
}

.p {
    @include p;
}

.p-mobile {
    @include p-mobile;
}

.p-desktop {
    @include p-desktop;
}

// Secondary paragraph text (smaller)
//---------------------------------------

@mixin alt-p {
    font-family: $paragraph-font-family;
    @include font-size($paragraph-alt-font-size-mobile);
    @include line-height($paragraph-alt-line-height-mobile);
    letter-spacing: $paragraph-alt-letter-spacing-mobile;
    font-weight: normal; // needed for safari
    @include respond(large-up) {
        @include font-size($paragraph-alt-font-size-desktop);
        @include line-height($paragraph-alt-line-height-desktop);
        letter-spacing: $paragraph-alt-letter-spacing-desktop;
    }
}

@mixin alt-p-mobile {
    font-family: $paragraph-font-family;
    @include font-size($paragraph-alt-font-size-mobile);
    @include line-height($paragraph-alt-line-height-mobile);
    letter-spacing: $paragraph-alt-letter-spacing-mobile;
    font-weight: normal; // needed for safari
}

@mixin alt-p-desktop {
    font-family: $paragraph-font-family;
    @include font-size($paragraph-alt-font-size-desktop);
    @include line-height($paragraph-alt-line-height-desktop);
    letter-spacing: $paragraph-alt-letter-spacing-desktop;
    font-weight: normal; // needed for safari
}

@mixin nav-p-desktop {
    font-family: $paragraph-font-family;
    @include font-size($nav-alt-font-size-desktop);
    @include line-height($nav-alt-line-height-desktop);
    letter-spacing: $nav-alt-letter-spacing-desktop;
    font-weight: normal; // needed for safari
}

@mixin alt-nav-mobile {
    font-family: $paragraph-font-family;
    @include font-size(9px);
    @include line-height($paragraph-alt-line-height-mobile);
    letter-spacing: $paragraph-alt-letter-spacing-mobile;
    font-weight: normal; // needed for safari
}

.alt-p {
    @include alt-p;
}

.alt-p-mobile {
    @include alt-p-mobile;
}

.alt-p-desktop {
    @include alt-p-desktop;
}

// Informational Text (smallest)
//---------------------------------------

@mixin info {
    font-family: $paragraph-font-family;
    @include font-size($paragraph-info-font-size-mobile);
    @include line-height($paragraph-info-line-height-mobile);
    letter-spacing: $paragraph-info-letter-spacing-mobile;
    font-weight: normal; // needed for safari
    @include respond(large-up) {
        @include font-size($paragraph-info-font-size-desktop);
        @include line-height($paragraph-info-line-height-desktop);
        letter-spacing: $paragraph-info-letter-spacing-desktop;
    }
}

@mixin info-mobile {
    font-family: $paragraph-font-family;
    @include font-size($paragraph-info-font-size-mobile);
    @include line-height($paragraph-info-line-height-mobile);
    letter-spacing: $paragraph-info-letter-spacing-mobile;
    font-weight: normal; // needed for safari
}

@mixin info-desktop {
    font-family: $paragraph-font-family;
    @include font-size($paragraph-info-font-size-desktop);
    @include line-height($paragraph-info-line-height-desktop);
    letter-spacing: $paragraph-info-letter-spacing-desktop;
    font-weight: normal; // needed for safari
}

.info {
    @include info;
}

.info-mobile {
    @include info-mobile;
}

.info-desktop {
    @include info-desktop;
}

/***** tooltips *****/
@mixin tooltip-text() {
    font-family: $link-tooltip-font;
    @include font-size($link-tooltip-font-size-mobile);
    color: $link-tooltip-color;
    text-decoration: $link-tooltip-decoration;
    font-weight: normal; // needed for safari
    &:hover {
        color: $link-tooltip-color;
    }
    @include respond(large-up) {
        @include font-size($link-tooltip-font-size-desktop);
    }
}

.tooltip-text {
    @include tooltip-text();
}
