.js {
    #suggestions {
        background-color: $color-body-bg;
        color: $color-primary;
        cursor: pointer;
        padding: 0;
        display: none;
        position: absolute;
        z-index: 8184;
        div {
            clear: both;
            height: 23px;
            padding: rem(5px);
            overflow: hidden;
            white-space: nowrap;
            .suggestionterm {
                float: left;
                overflow: hidden;
                width: 110px;
            }
            .hits {
                float: right;
                line-height: 1.8em;
                text-align: right;
                margin-left: rem(3px);
            }
        }
    }
}

.search-suggestions {
    clear: both;
    float: left;
}

.search-suggestion-wrapper {
    @include clearfix();
    position: absolute;
    border: $divider-secondary;
    background-color: $color-body-bg;
    padding: 0 rem(10px);
    left: 0;
    width: 100%;
    z-index: 1;
    @include respond($mobile-menu-breakpoint) {
        top: 60px;
        left: initial;
        right: 0;
        width: 380px;
        padding: rem(30px) rem(20px) rem(20px) rem(40px);
        &.full {
            width: 630px;
            .phrase-suggestions {
                width: 48.5%;
                float: left;
                padding-right: rem(40px);
                div:first-of-type {
                    margin: 0; // no top margin on first .hitgroup
                }
            }
            .product-suggestions {
                width: 51.5%;
                float: right;
            }
        }
    }

    .hitgroup {
        color: $color-primary;
        margin: rem(25px) 0;
        @include respond($mobile-menu-breakpoint) {
            margin: rem(30px) 0 0;
        }
        .header {
            margin: 0 0 rem(25px) 0;
            padding-bottom: rem(21px);
            border-bottom: $divider-primary;
            @include respond(large) {
                @include h4-mobile;
            }
        }
        .hit {
            @include alt-p;
            display: block;
            margin-bottom: rem(14px);
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
            @include respond(large) {
                @include font-size($paragraph-alt-font-size-mobile);
            }
        }
        .parent-category {
            color: $color-primary;
        }
    }
}

.phrase-suggestions {
    border-top: $divider-secondary;
    @include respond(desktop) {
        border-top: none;
    }
}

.product-suggestion {
    @include clearfix();
    border-top: $divider-primary;
    padding: rem(10px) 0;
    @include respond($mobile-menu-breakpoint) {
        padding: rem(11px) rem(11px) rem(11px) 0;
    }
    @include respond(large) {
        padding: rem(10px) 0;
    }
    .product-image {
        float: left;
        text-align: center;
        @include respond($mobile-menu-breakpoint) {
            width: 25%;
        }
        img {
            max-width: 100%;
            height: 65px;
            @include respond($mobile-menu-breakpoint) {
                height: auto;
            }
        }
    }
    .product-details {
        float: left;
        width: 54%;
        margin-left: rem(20px);
        .product-name {
            padding-bottom: rem(10px);
            @include h4();
            @include respond($mobile-menu-breakpoint) {
                padding-top: rem(12px);
            }
            @include respond(large) {
                @include h4-mobile;
            }
        }
        .product-price {
            @include alt-p;
            color: $color-primary;
            @include respond(large) {
                @include font-size($paragraph-alt-font-size-mobile);
            }
        }
    }
}

.search-phrase {
    @include h4();
    padding: rem(20px) 0;
    @include respond($mobile-menu-breakpoint) {
        margin: rem(4px) 0;
        padding: 0 0 rem(13px) 0;
    }
    @include respond(large) {
        @include h4-mobile;
    }
    .completed {
        .original {
            text-decoration: none;
        }
    }
    & a {
        @include h4();
        text-decoration: none;
        @include respond(large) {
            @include h4-mobile;
        }
    }
}
.site-search {
    .icon.Close.svg-Close-dims {
        @media screen and (min-width: 600px){
            display: none;
            visibility: hidden;
            height: 1px;
            width: 1px;
        }
    }
}
.suggestions {
    
    @include media-breakpoint-up(xl) {
        padding: 1.875rem .5rem 1.25rem .5rem;
        border: 1px solid #e1e1e1;
        max-height: 90vh;
        overflow-y: auto;
        .item {
            a.link:hover {
                .name{
                    text-decoration: none;
                }
            }
            .swatch-circle {
                border: none;
                width: 25%;
                height: auto;
                float: left;
            }
            .name {
                display: block;
                width: 68%;
                float: left;
                white-space: normal;
                text-wrap: wrap;
                font-size: 1rem;
                letter-spacing: .02rem;
                line-height: 1.25rem;
                text-transform: uppercase;
                font-family: Sackers Heavy Gothic,sans-serif;
                font-weight: 700;
            }
            .ellipsis {
                border-bottom: 1px solid #e1e1e1;
                padding: .5rem 0;
            }
        }
        .header:not(:first-child) {
            padding: 0;
            border: none;
        }
    }
    @include media-breakpoint-down(lg) {
        border: none;
        max-height: 80vh;
        overflow-y: auto;
        .item {
            padding-bottom: .25rem;
            .swatch-circle {
                border: none;
                width: 20%;
                height: auto;
                float: left;
            }
            span.name {
                display: block;
                width: 70%;
                float: left;
                text-wrap: wrap !important;
                font-size: .65rem;
                letter-spacing: .02rem;
                line-height: 1rem;
                text-transform: uppercase;
                font-family: Sackers Heavy Gothic,sans-serif;
                font-weight: 700;
                word-wrap: break-word;
                overflow-wrap: break-word;
                word-break: break-word;
                white-space: normal;
            }
            .ellipsis {
                border-bottom: 1px solid #e1e1e1;
                padding: .25rem 0;
            }
        }
    }
    @include media-breakpoint-down(xs) {

        .item {

            .name {

                width: 70%;
                max-width: 150px;
            }

        }
    }
    .header {
        .search-phrase {
            margin: 0.25rem 0;
            padding: 0 0 0.8125rem;
            font-size: 1rem;
            line-height: 20px;
            line-height: 1.25rem;
            letter-spacing: .02em;
            font-family: Sackers Heavy Gothic,sans-serif;
            font-weight: 700;
            text-transform: uppercase;
            color: #101820;
            border-bottom: 1px solid #e1e1e1;
            margin-left: 14px;
            @include media-breakpoint-down(md) {
                font-size: .65rem;
                margin-right: 14px;
            }
            em {
                color: #63666a;
            }
        }
    }
}