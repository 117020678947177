/****************************************************************
* Janie and Jack Theme
* Style Guide provided by Fluid
* NOTE: mobile specs provided in Fluid Style Guide were cut in half
* modified mobile specs have their former values commented after them
****************************************************************/

/****************************************************************
*
* viewports
* See mixins file for viewport includes
* small = 320px/640px (retina) - 767px
* large = 768px - 960px
* desktop = 961px+ (max content width is 1170px)
*
****************************************************************/

@charset "UTF-8";
@import "~base/variables";

$small-breakpoint: 320px;
$medium-breakpoint: 640px; // using as retina
$large-breakpoint: 768px;
$desktop-breakpoint: 961px;
$max-content-breakpoint: 1170px;

/****************************************************************
*
* page layout dimensions
*
****************************************************************/
$content-width-gutters: 10px;
$mobile-menu-breakpoint: $desktop-breakpoint;
$mobile-menu-width: 256px;
$left-nav-width: 25%; // content and search refinement left navs
$primary-content-width: 75%;

$header-top-padding-desktop: 8px;
$header-height-desktop: 170px;
$header-top-margin-mobile: 17px; //20px;
$header-height-mobile: 37px; //110px

$nav-min-width-desktop: 832px;
$nav-min-width-desktop-narrow: 677px;

$footer-padding-desktop: 25px 0 15px;
$footer-padding-mobile: 20px 7.5px 15px; //40px 15px 30px;

/****************************************************************
*
* colors
* Specific use colors are identified in the following sections
*
****************************************************************/
// primary colors
$navy: #1b375d;
$dark-grey: #63666a;
$white: #fff;
$janie-black: #101820;

// secondary colors
$red: #c8102e;
$blue: #3c759a;
$medium-grey: #e0e3e7;
$light-grey: #efefef;
$disabled-grey: #e1e1e1;
$black: #071d36; // CTA active states
$trans: transparent;
$jack-black: #000000;
$black-1: #495057;
$scroll-grey: #d3d3d3;
$janie-gray1: #f2f2f2;
$gray-2: #e9ecef;

/**********************************************
* Color Aliases
* some colors are identified in their sections
***********************************************/
$color-primary: $dark-grey;
$color-body-bg: $white;
$color-disabled: $disabled-grey;

$color-body-bg-modal: black;
$color-bg-light: $light-grey;
$color-bg-medium: $medium-grey;
$color-promo-bg: $light-grey;
$color-promo-bg-dark: $black;
$color-promo-dark: $black;
$color-promo-text: $white;
$color-sales-price: $red;
$color-standard-price: $medium-grey;
$color-standard-price-dark: $dark-grey;
$color-refinement-header: $black;
$color-approaching-promo: $dark-grey;
$color-account-headers: $dark-grey;
$color-menu-badge: $red;
$color-nav-age-flag: $black;
$color-dark-label: $black;
$color-success: $dark-grey;
$color-success-email: $dark-grey;
$color-table-header: $dark-grey;
$color-filter-background-mobile: $white;

/**********************************************
* Storelocator
***********************************************/
$store-locator-bg: $light-grey;

/**********************************************
* Icons
***********************************************/
$icon-color-light: $dark-grey;
$icon-color-footer: $white;
$icon-color-dark: $navy;

$icon-pagination-width: 8px;
$icon-pagination-color: $navy;

$icon-home: url("../images/svg-icons/Home.svg");
$icon-arrow: url("../images/svg-icons/Arrow_inline.svg");
$icon-arrow-small: url("../images/svg-icons/Arrow_small.svg");
$icon-arrow-white: url("../images/svg-icons/Arrow_White_Inline.svg");
$icon-tooltip: url("../images/svg-icons/icon_tooltip.svg");
$icon-error: url("../images/svg-icons/icon_error.svg");
$icon-close: url("../images/svg-icons/Close_Black.svg");
$icon-pencil: url("../images/svg-icons/Review_Pencil.svg");

$icon-top-asset-width: 14px;
$icon-top-asset-height: 14px;

$icon-small-arrow-size-mobile: 9px;
$icon-small-arrow-size-desktop: 14px;

/**********************************************
* Headings
* see _text_styles.scss for includes
***********************************************/
$h1-color: $navy;
$h1-font: $font-heading;
$h1-font-size-desktop: 24px;
$h1-line-height-desktop: 36px;
$h1-letter-spacing-desktop: .28em; //280 tracking
$h1-font-size-mobile: 18px; //36px;
$h1-line-height-mobile: 21px; //42px;
$h1-letter-spacing-mobile: .14em; //280 tracking
$h1-text-transform: uppercase;

$h2-color: $navy;
$h2-font: $font-heading;
$h2-font-size-desktop: 18px;
$h2-line-height-desktop: 24px;
$h2-letter-spacing-desktop: .28em; //280 tracking
$h2-font-size-mobile: 15px; //30px;
$h2-line-height-mobile: 18px; //36px;
$h2-letter-spacing-mobile: .14em; //280 tracking
$h2-text-transform: uppercase;

$h3-color: $navy;
$h3-font: $font-heading;
$h3-font-size-desktop: 16px;
$h3-line-height-desktop: 24px;
$h3-letter-spacing-desktop: .28em; //280 tracking
$h3-font-size-mobile: 13.5px; //27px;
$h3-line-height-mobile: 17px; //34px;
$h3-letter-spacing-mobile: .14em; //280 tracking
$h3-text-transform: uppercase;

$h4-color: $dark-grey;
$h4-font: $font-heading;
$h4-font-size-desktop: 16px;
$h4-line-height-desktop: 20px;
$h4-letter-spacing-desktop: .02em; // 20px tracking
$h4-font-size-mobile: 12px; //24px;
$h4-line-height-mobile: 14px; //28px;
$h4-letter-spacing-mobile: .025em; // 25px tracking
$h4-text-transform: uppercase;

$h5-color: $dark-grey;
$h5-font: $font-heading;
$h5-font-size-desktop: 13px;
$h5-line-height-desktop: 20px;
$h5-letter-spacing-desktop: .02em; // 20px tracking
$h5-font-size-mobile: 11px; //22px;
$h5-line-height-mobile: 13px; //26px;
$h5-letter-spacing-mobile: .02em; // 20px tracking
$h5-text-transform: uppercase;

$h6-color: $dark-grey;
$h6-font: $font-body;
$h6-font-size-desktop: 14px;
$h6-line-height-desktop: 20px;
$h6-letter-spacing-desktop: .025em; // 25px tracking
$h6-font-size-mobile: 13px; //26px;
$h6-line-height-mobile: 12.5px; //25px;
$h6-letter-spacing: .05em; // 50px tracking
$h6-text-transform: uppercase;

$h7-color: $dark-grey;
$h7-font: $font-body;
$h7-font-size-desktop: 12px;
$h7-line-height-desktop: 16px;
$h7-font-size-mobile: 10px; //20px;
$h7-line-height-mobile: 10px; //20px;
$h7-letter-spacing: normal;

/**********************************************
* Promo Headings
* Mobile sizes cut in half from 2x specs
***********************************************/

$promo1-color: $navy;
$promo1-font: $font-promo;
$promo1-font-size-desktop: 100px;
$promo1-line-height-desktop: 100px;
$promo1-font-size-mobile: 50px; //100px;
$promo1-line-height-mobile: 50px; //100px;
$promo1-text-transform: uppercase;

$promo2-color: $navy;
$promo2-font: $font-promo;
$promo2-font-size-desktop: 48px;
$promo2-line-height-desktop: 52px;
$promo2-font-size-mobile: 24px; //48px;
$promo2-line-height-mobile: 26px; //521px;
$promo2-text-transform: uppercase;

$promo3-color: $navy;
$promo3-font: $font-promo;
$promo3-font-size-desktop: 32px;
$promo3-line-height-desktop: 36px;
$promo3-font-size-mobile: 16px; //32px;
$promo3-line-height-mobile: 18px; //36px;
$promo3-text-transform: uppercase;

$promo4-color: $navy;
$promo4-font: $font-heading;
$promo4-font-size-desktop: 16px;
$promo4-line-height-desktop: 20px;
$promo4-font-size-mobile: 10px; //20px;
$promo4-line-height-mobile: 10px; //20px;
$promo4-text-transform: uppercase;

$promo5-color: $navy;
$promo5-font: $font-heading;
$promo5-font-size-desktop: 12px;
$promo5-line-height-desktop: 18px;
$promo5-font-size-mobile: 8px; //16px;
$promo5-line-height-mobile: 8px; //16px;
$promo5-text-transform: uppercase;

$promo-color-dark: $navy;
$promo-color-light: $white;

/**********************************************
* Border (see also dividers below)
***********************************************/
$border-radius: 0;
$border-width: 1px;
$border-style: solid;
$border-color: $medium-grey;
$border-color-disabled: $disabled-grey;

$border-nav-divider: $medium-grey;

$border-primary: $border-width $border-style $border-color;
$border-secondary: $border-width $border-style $light-grey;
$border-dark: $border-width $border-style $border-color;
$box-shadow-nav: 0 4px 2px -2px $medium-grey;

/****************************************************************
*
* base_elements
*
****************************************************************/

/**********************************************
* body text
* Mobile sizes cut in half from 2x specs
***********************************************/
$font-size-base: 16px;

$paragraph-font-family: $font-body;
$paragraph-color: $dark-grey;

$paragraph-font-size-desktop: $font-size-base;
$paragraph-line-height-desktop: 22px;
$paragraph-letter-spacing-desktop: .025em;
$paragraph-font-size-mobile: 14px; //28px;
$paragraph-line-height-mobile: 17.5px; //35px;
$paragraph-letter-spacing-mobile: .025em;

$paragraph-alt-font-size-desktop: 14px;
$paragraph-alt-line-height-desktop: 20px;
$paragraph-alt-letter-spacing-desktop: .025em;
$paragraph-alt-font-size-mobile: 13px; //26px;
$paragraph-alt-line-height-mobile: 15px; //30px;
$paragraph-alt-letter-spacing-mobile: .025em;

$paragraph-info-font-size-desktop: 12px;
$paragraph-info-line-height-desktop: 20px;
$paragraph-info-letter-spacing-desktop: .025em;
$paragraph-info-font-size-mobile: 12px; //24px;
$paragraph-info-line-height-mobile: 14px; //28px;
$paragraph-info-letter-spacing-mobile: .025em;

$nav-alt-font-size-desktop: 12px;
$nav-alt-line-height-desktop: 20px;
$nav-alt-letter-spacing-desktop: .025em;


/**********************************************
* links
***********************************************/

$link-base-hover-color: $navy;
$link-base-text-decoration: none;
$link-base-text-decoration-hover: underline;

// header utility links
$link-utility-color: $dark-grey;
$link-utility-color-user: $dark-grey;
$link-utility-text-decoration: none;
$link-utility-text-decoration-hover: none;
$link-utility-user-color: $dark-grey;

// navigation links
$link-nav-color: $dark-grey;
$link-nav-color-hover: $navy;
$link-nav-text-decoration: none;
$link-nav-text-transform: none;

// refinement links
$link-refinement-color: $dark-grey;
$link-refinement-text-decoration: none;

// small links
$link-small-color: $dark-grey;
$link-small-color-hover: $dark-grey;
$link-small-text-decoration: underline;
$link-small-text-decoration-hover: underline;

// breadcrumbs
$link-breadcrumb-color: $dark-grey;
$link-breadcrumb-color-hover: $dark-grey;
$link-breadcrumb-text-decoration: none;
$link-breadcrumb-hover-text-decoration: underline;

// call to action links
$link-cta-color: $navy;
$link-cta-color-hover: $navy;
$link-cta-icon: $icon-arrow;
$link-cta-text-transform: uppercase;
$link-cta-text-decoration: none;
$link-cta-text-decoration-hover: underline;

$link-cta-alt-icon-width-desktop: 17px;
$link-cta-alt-icon-height-desktop: 23px;
$link-cta-alt-text-transform: uppercase;
$link-cta-alt-icon-top-position-desktop: -3px;

// tooltip links
$link-tooltip-font: $font-heading;
$link-tooltip-font-size-desktop: 13px;
$link-tooltip-font-size-mobile: 9px; //18px;
$link-tooltip-color: $navy;
$link-tooltip-decoration: uppercase;

/****************************************************************
*
* form_components
*
****************************************************************/

/**********************************************
* inputs
***********************************************/

$input-bg-color: $white;
$input-color: $dark-grey;
$input-border-width: $border-width;
$input-border-color: $dark-grey;
$input-border-color-error: $red;
$input-border: $input-border-width $border-style $input-border-color;
$input-placeholder-text-transform: uppercase;
$input-bottom-margin-mobile: 10px;
$input-bottom-margin-desktop: 13px;

$input-height-desktop: 52px;
$input-padding-left-desktop: 20px;
$input-font-size-desktop: $h6-font-size-desktop;
$input-border-radius-desktop: 0;

$input-small-height-desktop: 45px;
$input-small-border-radius-desktop: 0;
$input-small-padding-desktop: 15px;
$input-small-height-mobile: 36px;
$input-small-border-radius-mobile: 0;
$input-small-padding-mobile: 15px;
$input-small-padding-left-desktop: 15px;
$input-small-padding-left-mobile: 15px;

$input-height-mobile: 45px; //90px;
$input-font-size-mobile: $h5-font-size-mobile;
$input-padding-left-mobile: 20px; //40px;
$input-border-radius-mobile: 0;

$input-outline: 0 0 5px 0 $dark-grey;
$input-select-item: 0 3px 2px -2px $dark-grey, 0 -3px 2px -2px $medium-grey;

/**********************************************
* custom select inputs
***********************************************/
$input-select-bg: $white;
$input-select-item-focus: $white;

$input-select-arrow-down: url("../images/svg-icons/Arrow_down.svg");
$input-select-arrow-up: url("../images/svg-icons/Arrow_up.svg");
$input-select-arrow-width-desktop: 15px;
$input-select-arrow-height-desktop: 10px;
$input-select-arrow-width-mobile: 12px; //11px
$input-select-arrow-height-mobile: 8px; // 16px
$input-select-arrow-top-desktop: 3px;

$input-select-max-height-desktop: 178px;
$input-select-short-max-height-desktop: 120px;
$input-select-max-height-mobile: 115px;
$input-select-short-max-height-mobile: 130px;
$input-select-height-desktop: 50px;
$input-select-short-height-desktop: 40px;
$input-select-height-mobile: 45px;
$input-select-short-height-mobile: 36px;
$input-select-radius-desktop: 0;
$input-select-short-radius-desktop: 0;
$input-select-radius-mobile: 0;
$input-select-short-radius-mobile: 0;
$input-select-label-color-hover: $navy;
$input-select-active: $dark-grey;

$input-select-button-color: $dark-grey;
$input-select-padding-right-desktop: 20px;
$input-select-short-padding-right-desktop: 15px;
$input-select-padding-left-desktop: 25px;
$input-select-short-padding-left-desktop: 15px;
$input-select-padding-right-mobile: 15px; //30px;
$input-select-short-padding-right-mobile: 15px; //30px;
$input-select-padding-left-mobile: 20px; //40px;
$input-select-short-padding-left-mobile: 15px; //30px;

$input-select-focus: $navy;


/**********************************************
* custom checkbox inputs
***********************************************/
$input-checkbox-content-checkmark: url("../images/svg-icons/Checkbox_Checked.svg");
$input-checkbox-content-checkmark-disabled: url("../images/svg-icons/Checkbox_Disabled.svg"); // need to change

$input-checkbox-height-desktop: 25px;
$input-checkbox-width-desktop: 25px;
$input-checkbox-margin: 10px;
$checkbox-border: $border-width $border-style $black;

$input-checkbox-height-mobile: 30px; //60px;
$input-checkbox-width-mobile: 30px; //60px;

/**********************************************
* custom radio inputs
***********************************************/
$input-radio-dot: url("../images/svg-icons/Radio_Selected.svg");
$input-radio-border-color-selected: $black;

$input-radio-height-desktop: 25px;
$input-checkbox-width-desktop: 25px;

$input-radio-height-mobile: 30px; //60px;
$input-radio-width-mobile: 30px; //60px;

/**********************************************
* search input
***********************************************/
$input-search-bg-color: $light-grey;
$input-text-transform: none;
$input-search-icon-width-desktop: 20px;
$input-search-icon-width-mobile: 17px; //34px;
$input-search-border-color: $light-grey;
$input-search-icon-color: $dark-grey;
$input-search-border: 2px $border-style $input-search-border-color;
$input-search-border-mobile: 1px $border-style $input-search-border-color;
$input-search-line-height: 42px; // less borders
$input-search-line-height-mobile: 43px; // less borders
$input-search-text-color: $dark-grey;

/**********************************************
* Buttons
***********************************************/
// Styles for all buttons
// desktop large uses h5 for text
$button-large-height-desktop: 52px;
$button-large-height-mobile: 45px; //90px
$button-large-side-padding: 30px;
$button-large-bottom-margin: 15px;
$button-border-radius: 0;
$button-disabled-bg: $color-disabled;
$button-disabled-color: $dark-grey;

// desktop small uses h6 for text
$button-small-height-desktop: 45px;
$button-small-side-padding: 25px;
$button-small-bottom-margin: 10px;
$button-small-height-mobile: 37.5px; // 75px

// Primary button (navy-button)
$button-primary-bg-color: $navy;
$button-primary-bg-color-hover: $black;
$button-primary-color: $white;

// Secondary Button (grey-button)
$button-dark-bg-color: $blue;
$button-dark-bg-color-hover: $navy;
$button-dark-color: $white;

// Stroke Button
$button-stroke-bg-color: $white;
$button-stroke-border-color: $navy;
$button-stroke-border-width: 1px;
$button-stroke-border-width-mobile: 2px;
$button-stroke-bg-color-hover: $navy;
$button-stroke-color: $navy;
$button-stroke-color-hover: $white;
$button-stroke-letter-spacing: .055em;
$button-stroke-side-padding-small: 25px;

// Size Refinement Buttons
$button-size-bg-color: $white;
$button-size-color: $dark-grey;
$button-size-bg-color-selected: $navy;
$button-size-color-selected: $white;
$button-size-color-disabled: $dark-grey;
$button-size-border-color: $dark-grey;
$button-size-disabled-pdp: $medium-grey;

$button-size-width-desktop: 70px;
$button-size-height-desktop: 32px; //34 with border
$button-size-padding-desktop: 2px; // 4px total
$button-size-padding-desktop-side: 2px; // 4px total
$button-size-pdp-width-desktop: 68px; // 70px
$button-size-pdp-height-desktop: 32px; // 34px

$button-size-width-mobile: 57px; //114px;
$button-size-height-mobile: 34px; //67px with border
$button-size-pdp-width-mobile: 52px; //80px;
$button-size-pdp-height-mobile: 34px; //67px;
$button-size-padding-mobile: 2px 1px;

$button-cardtype-width-desktop: 100px;
$button-cardtype-height-desktop: 70px;
$button-cardtype-width-mobile: 128px;
$button-cardtype-height-mobile: 90px;

// Color Refinement Buttons
$button-color-border-selected: $dark-grey;
$button-color-box-shadow: $white;

$button-color-size-desktop: 36px;
$button-color-padding-desktop: 3.5px; // 7 total
$button-color-padding-mobile: 3.75px; // 7.5 total
$button-color-size-mobile: 35.5px; //71px;
$button-color-pdp-size-mobile: 36.5px; //73px;
$button-color-selected-box-shadow-mobile: inset 0 0 0 3px $white, 0 0 0 2px $button-color-border-selected;
$button-color-selected-box-shadow-desktop: inset 0 0 0 3px $white, 0 0 0 2px $button-color-border-selected;


$button-pdp-selected-box-shadow-mobile: inset 0 0 0 1px $white, 0 0 0 1px $button-color-border-selected;
$button-pdp-selected-box-shadow-desktop: inset 0 0 0 1px $white, 0 0 0 1px $button-color-border-selected;
$button-pdp-selected-swatch-border: 2px solid $white;

$swatch-border-radius: 0;

$refinement-toggle-button-border: $input-border;

/**********************************************
* form errors
***********************************************/
$color-error: $red;
$color-error-bg: $white;
$icon-error-width-desktop: 15px;
$icon-error-width-mobile: 9px; // 18px
$error-margin-right: 6px;
$error-top-desktop: 3px;
$error-top-mobile: 0;
/**********************************************
* PDP
***********************************************/
$pdp-out-of-stock: $red;
$pdp-font-family: "Nunito Sans", sans-serif;
$pdp-font-size-lg: 1.5em;
$pdp-product-name-text-transform: capitalize;
$pdp-review-link-color: $dark-grey;
$pdp-thumb-selected-border: 1px solid $navy;

/**********************************************
* Login
***********************************************/
$login-bg: $light-grey;
$login-form-bg: $white;
$login-form-padding: 12px;
$login-form-caption: $black;
$login-form-size: 990px;
$login-tab-font-size: 14px;
$login-tab-height: 64px;
$login-tab-border: $black;
$login-subhead-mobile: 15px;
$login-description-mobile: 12px;
$login-text-mobile: 13px;
$login-rewards-color: $black;
$login-rewards-size-mobile: 24px;
$login-rewards-email-mobile: 11px;
$login-communication-link-color: $black;


/****************************************************************
*
* primary_region_elements.scss
*
****************************************************************/

/****************************************************************
* additional elements
****************************************************************/
$clp-clearfilter-color: $black;
$clp-arrow-size-mobile: 14px;
$pagination-color: $black;
$pagination-border-color: $black;
$overlay-opacity: .5;

/****************************************************************
* Minicart
****************************************************************/

$minicart-qty-right-position: 0;
$minicart-qty-top-position-mobile: 5px;
$minicart-qty-top-position-desktop: 9px;
$minicart-qty-width: 23px;
$minicart-qty-width-mobile: 25px;
$minicart-slot-background: $light-grey;
$minicart-qty-color: $dark-grey;

/****************************************************************
* Find In Store
****************************************************************/
$fis-step-bg: $black;
$fis-step-bg-selected: $light-grey;
$fis-step-color: $white;
$fis-step-color-selected: $dark-grey;
$fis-step-circle-bg: $white;
$fis-step-circle-bg-selected: $dark-grey;
$fis-step-circle-color: $black;
$fis-step-circle-color-selected: $white;
$fis-step-close-color: $white;
$fis-step-close-color-selected: $dark-grey;
$fis-step-height-desktop: 60px;
$fis-step-circle-desktop: 27px;
$fis-step-close-desktop: 12px;
$fis-step-height-mobile: 50px;
$fis-step-circle-mobile: 21px;
$fis-step-close-mobile: 15px;
$fis-item-font-size: 14px;
$fis-item-line-height: 20px;
$fis-icon-desktop: 26px;
$fis-icon-mobile: 26px;

/**********************************************
* Horizontal Tab Styles
***********************************************/
$tab-horizontal-padding-desktop: 20px;
$tab-horizontal-padding-mobile: 10px;
$tab-horizontal-color-hover: $dark-grey;
$tab-horizontal-divider: 1px solid $disabled-grey;
$tab-horizontal-text-decoration-hover: 3px solid $dark-grey;

$tab-horizontal-divider-height-desktop: 10px;
$tab-horizontal-active-desktop: 3px solid $navy;
$tab-horizontal-active-border-width-desktop: 5px; // 3px in guide, bumped up to match appearance

$tab-horizontal-divider-height-mobile: 8.5px; //17px;
$tab-horizontal-active-mobile: 4px solid $dark-grey;
$tab-horizontal-active-border-width-mobile: 6px; // 4px in guide, bumped up to match appearance

/**********************************************
* Tooltip Container
***********************************************/
$tooltip-border-color: $light-grey;

$tooltip-icon-size-desktop: 10px;
$tooltip-padding-desktop: 30px;
$tooltip-maxwidth-desktop: 35%;

$tooltip-icon-size-mobile: 10.5px; //21px;
$tooltip-padding-mobile: 25px; //50px;
$tooltip-maxwidth-mobile: 80%;

/**********************************************
* Divider Lines
***********************************************/

$divider-primary: 1px solid $disabled-grey;
$divider-secondary: 1px solid $light-grey;
$divider-dotted-grey: 1px solid $medium-grey;
$divider-mobile-nav: $divider-primary;

//border aliases not used in this site
$divider-dotted-orange: none;
$collection-landing: none;
$divider-rewards: none;

/**********************************************
* Floating Box
***********************************************/
$floatingBox-width: 217px;
$floatingBox-height: 142px;
$floatingBox-padding: 30px;
$floatingBox-close: 12px;
$floatingBox-font-size: 12px;
$floatingBox-shadow: 0 2px 7.83px 1.17px rgba(204, 204, 204, 0.29);
$floatingBox-icon: 14px;

/**********************************************
* Dashboard
***********************************************/
$dashboard-border: 12px $border-style $light-grey;
$dashboard-border-mobile: 10px $border-style $light-grey;
$dashboard-default-color: $dark-grey;
$dashboard-address-line-height: 25px;
$dashboard-address-line-height-address: 30px;
$dashboard-address-line-height-mobile: 16px;
$dashboard-color-icon-enrolled: $medium-grey;
$dashboard-color-icon-not-enrolled: $black;

/**********************************************
* Gift Landing and PDP
***********************************************/
$gift-landing-header-height: 160px;
$gift-landing-header-title-size: 54px;
$gift-landing-header-title-size-mobile: 27px;
$gift-landing-header-title-color: $black;
$gift-form-size: 740px;
$gift-balance-size: 811px;
$gift-balance-padding-top: 25px;
$gift-balance-padding-left: 30px;
$gift-balance-padding-top-mobile: 12px;
$gift-balance-padding-left-mobile: 10px;
$gift-balance-bg: $white;
$gift-or-bg: $black;
$gift-or-color: $white;
$gift-bg-color: $light-grey;
$gift-or-border: 2px solid $white;
$gift-or-size: 75px;
$gift-or-font-size: 32px;
$gift-or-line-height-size: 71px;

/**********************************************
* Search
***********************************************/
$cbp-slot-banner-color: $dark-grey;
$no-hits-button-margin-top: 0;

/**********************************************
* Checkout
***********************************************/
$checkout-step-bg: $light-grey;
$checkout-border: $light-grey;
$checkout-step-height: 60px;
$checkout-step-height-mobile: 42px;
$checkout-login-subhead-color: $black;
$checkout-section-heading: 16px;
$checkout-section-heading-color-mobile: $dark-grey;
$checkout-section-heading-mobile: 15px;
$checkout-section-discount: $red;
$checkout-section-shipping-discount: $dark-grey;

/**********************************************
* Shopping For
***********************************************/
$shoppingfor-border-mobile: 10px $border-style $light-grey;
$shoppingfor-border: 12px $border-style $light-grey;
$shoppingfor-link: $black;
$shoppingfor-info-mobile: 15px;

/**********************************************
* Badges and Flags
***********************************************/

// maps to Gymboree red ribbon
$badge-ribbon-bg-color: $navy;
$badge-ribbon-color: $white;
$badge-ribbon-height-mobile: 30px; //58px;
$badge-ribbon-height-desktop: 50px;

// maps to Gymboree blue tile flag
$badge-flag-bg-color: $white;
$badge-flag-color: $navy;
$badge-flag-height-mobile: 14px; //28px;
$badge-flag-height-desktop: 20px;
$badge-flag-padding-left-desktop: 5px;

// maps to Gymboree online flag
$badge-online-color: $blue;

// maps to Gymboree sale flag
$badge-sale-color: $red;

// maps to Gymboree Section Title with Orange line
$section-title-border-desktop: none;
$section-title-border-width-desktop: 215px;
$section-title-border-mobile: none;
$section-title-border-width-mobile: 125px; //250px;

// maps to Gymboree Navigation Title with Orange line
$section-title-border-nav: none;

// maps to Gymboree blue PDP Badge
$badge-flag-pdp-bg-color: $white;
$badge-flag-pdp-height-mobile: 14px; //28px;
$badge-flag-pdp-height-desktop: 21px;

/**********************************************
* flag-icon-css
***********************************************/
$flag-icon-css-path: "../lib/flag-icon-css/flags";

/**********************************************
* Refinement swatch colors
***********************************************/

$swatch-types: (
    black #000,
    beige #f5f5dc,
    blue blue,
    purple purple,
    red red,
    brown #783201,
    green green,
    grey #8f979d,
    pink #fe249a,
    orange orange,
    white #fff,
    yellow #ff0,
    navy navy
);

/**********************************************
* footer section
***********************************************/
$footer-secondrow-txt: $dark-grey;
$footer-secondrow-bg: repeating-linear-gradient(to right, $medium-grey, $medium-grey 5%, $white 5%, $white 10%);
$footer-secondrow-bg-mobile: repeating-linear-gradient(to right, $medium-grey, $medium-grey 11.2%, $white 11.2%, $white 22.4%);
$footer-button-bg: $navy;
$footer-button-color: $white;
$footer-button-bg-hover: $navy;
$footer-secondrow-font: $font-heading;
$footer-secondrow-font-size: 14px; // not a global style
$footer-secondrow-line-height: 14px;
$footer-secondrow-bottom-border: $divider-primary;

$footer-stores-width: 11%;
$footer-social-width: 33%;
$footer-email-wrapper-width: 56%;
$footer-email-width: 40%;
$footer-email-input-width: 52%;

$footer-social-padding-left: 0;
$footer-social-top: -3px;
$footer-email-signup-padding-left: 40px;
$footer-email-signup-margin-top: 12px;
$footer-email-input-margin-top: 2px;

/**********************************************
* add to cart dialog
***********************************************/
$addtocart-dialog-check-svg-color: $navy;
$addtocart-dialog-product-price-original-fontcolor: $navy;
$addtocart-icon-size-mobile: 18px;
$addtocart-icon-size-desktop: 30px;


/**********************************************
* MY ACCOUNT PAGE
***********************************************/

$myaccount-section-title-border-desktop: 4px solid $light-grey;
$myaccount-section-title-border-width-desktop: 215px;
$myaccount-section-title-border-mobile: 3.5px solid $light-grey;
$myaccount-section-title-border-width-mobile: 125px; //250px;


$myaccount-nav-title-font-size-desktop: 22px;
$myaccount-nav-title-line-height-desktop: 22px;
$myaccount-nav-title-font-size-mobile: 12px; //24px;
$myaccount-nav-title-line-height-mobile: 15px; //30px;

$myaccount-color-defaultaddress-txt-color: $color-primary;
$myaccount-address-line-height-desktop: 18px;
$myaccount-addressname-line-height-desktop: 28px;
$myaccount-mini-button-font-size-desktop: 12px;

$myaccount-mini-address-title-line-height-desktop: 25px;
$myaccount-mini-address-button-color: $black;
$myaccount-mini-address-button-color-hover: $black;

$myaccount-addressname-line-height-desktop: 22px;
$myaccount-mini-address-font-size-mobile: 15px;
$myaccount-orderhistory-gray-accent-color: $light-grey;
$myaccount-trackingnum-txt-color: $dark-grey;

$myaccountcreate-background-color: $light-grey;

$pricing-in-usd-font: "Nunito Sans", sans-serif;
$pricing-in-usd-color: #63666a;

// svg url icons
$close-svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 55 55'%3E%3Crect fill='%231b375d' x='24.27' y='-8.03' width='6.26' height='71.25' transform='translate(-11.49 27.46) rotate(-45)'/%3E%3Crect fill='%231b375d' x='24.27' y='-8.03' width='6.26' height='71.25' transform='translate(27.26 66.49) rotate(-135)'/%3E%3C/svg%3E%0A");
$icon-home: url("../images/svg-icons/Home.svg");
$select-dropdown: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 55 55'%3E%3Cpolyline fill='none' stroke='%2363666A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' points='36.5,22.3 27.3,32.1 18.1,22.3 '/%3E%3C/svg%3E%0A");
$alert-icon: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cdefs%3E%3C/defs%3E%3Ctitle%3EAlert%3C/title%3E%3Ccircle class='cls-1' cx='16' cy='15.91' r='15.9' fill='%23c8102e'/%3E%3Cpath class='cls-2' d='M17.41,20H14.75L13.88,6.53h4.4Zm-1.34,1.78A2.19,2.19,0,0,1,18.25,24a2.17,2.17,0,0,1-2.19,2.16A2.14,2.14,0,0,1,13.93,24,2.17,2.17,0,0,1,16.07,21.79Z' fill='%23fff'/%3E%3C/svg%3E%0A");

$lay-ease-in: cubic-bezier(0.84, 0.045, 0.885, 0.97);
$lay-ease-out: cubic-bezier(0.12, 0.045, 0.085, 0.96);
$lay-ease-inout: cubic-bezier(0.335, 0.01, 0.45, 0.995);

/**********************************************
* Site Pricing
***********************************************/
$price-std-font-family: "Sackers Heavy Gothic", sans-serif;
$plp-price-font-family: $price-std-font-family;
$wishlist-price-font-family: $price-std-font-family;
$checkout-price-font-family: $price-std-font-family;
$plp-price-font-size: 0.875rem;

/**********************************************
* Cart Page
***********************************************/

$cart-page-header-font-family: "Sackers Heavy Gothic", sans-serif;
