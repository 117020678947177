// Custom Button Component

@import "../utilities/mixin";

// Custom Button Component Styles
.btn {
  @include fontsize(13px, 52px);

  font-family: $font-heading;
  padding: 0;
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: rem(0.26px);
  border-radius: 0;

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.3;
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-primary {
  @include set-button($white, $navy, transparent);

  @include fontsize(14px, 54px);
  height: rem(45px);

  @include media-breakpoint-up(sm) {
    padding: 0;
    height: rem(52px);
  }

  &:not(:disabled),
  &:not(:disabled):not(:disabled) {
    &:hover,
    &:active,
    &:focus {
      @include set-button($white, $black, transparent);
    }
  }

  &:disabled,
  &.disabled {
    @include set-button($disabled-grey, $dark-grey, $disabled-grey);
  }
}

.btn-outline-primary {
  @include set-button($black, $white, $black);

  @include fontsize(11px, 45px);

  @include media-breakpoint-up(sm) {
    @include fontsize(13px, 52px);
    padding: 0;
  }

  &:not(:disabled),
  &:not(:disabled):not(:disabled) {
    &:hover,
    &:active {
      @include set-button($white, $black, none);
    }
  }
}
