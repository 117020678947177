@import "~base/components/hero";

h1.page-title {
    position: relative;
    color: white;
    padding: 0.3125em 0.625em 0.3125em calc($grid-gutter-width / 2);
    background-color: #{var(--skin-primary-color-2)};
    display: inline-block;
    margin: 0.9375em 0;
    font-size: 30px;
    padding: 10px 0;

    @include media-breakpoint-up(sm) { font-size: 30px; padding: 10px 0; }

    @include media-breakpoint-up(md) { font-size: 30px; padding: 10px 0; }

    &::before {
        content: "";
        background-color: #{var(--skin-primary-color-2)};
        height: 100%;
        width: 0;
        position: absolute;
        left: 0;
        top: 0;

        @include media-breakpoint-only(xl) {
            width: calc((100vw - #{map-get($container-max-widths, xl)}) / 2);
            left: calc((100vw - #{map-get($container-max-widths, xl)}) / 2 * -1);
        }

        @include media-breakpoint-only(lg) {
            width: calc((100vw - #{map-get($container-max-widths, lg)}) / 2);
            left: calc((100vw - #{map-get($container-max-widths, lg)}) / 2 * -1);
        }

        @include media-breakpoint-only(md) {
            width: calc((100vw - #{map-get($container-max-widths, md)}) / 2);
            left: calc((100vw - #{map-get($container-max-widths, md)}) / 2 * -1);
        }
    }

    @include media-breakpoint-only(xl) {
        left: calc((100% - #{map-get($container-max-widths, xl)}) / 2);
    }

    @include media-breakpoint-only(lg) {
        left: calc((100% - #{map-get($container-max-widths, lg)}) / 2);
    }

    @include media-breakpoint-only(md) {
        left: calc((100% - #{map-get($container-max-widths, md)}) / 2);
    }

    @include media-breakpoint-down(sm) {
        left: 0;
    }
}

