@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-loader-path: "../lib/slick/" !default;
$slick-arrow-color: $black !default;
$slick-arrow-bg-color: $trans !default;
$slick-arrow-bg-color-hover: $trans !default;
$slick-dot-color: $white !default;
$slick-dot-color-hover: $white !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-dot-size: rem(25px) !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    } @else {
        @return url($slick-loader-path + $url);
    }
}

/* Slider */

/* .slick-list {
    .slick-loading & {
        background: $white slick-image-url("ajax-loader.gif") center center no-repeat;
    }
} */

/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: rem(32px);
    width: rem(24px);
    min-height: 0;
    font-weight: initial;
    line-height: 0;
    font-size: 0;
    border-radius: 0;
    cursor: pointer;
    background: $slick-arrow-bg-color;
    color: $trans;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    opacity: $slick-opacity-default;
    z-index: 1;
    &:hover,
    &:focus {
        box-shadow: none;
        background: $slick-arrow-bg-color-hover;
        opacity: $slick-opacity-on-hover;
        color: $trans;
    }
    &.slick-disabled::before {
        opacity: $slick-opacity-not-active;
    }
    &::before {
        content: "";
        display: inline-block;
        border-style: solid;
        border-width: 1px 1px 0 0;
        border-color: $slick-arrow-color;
        width: rem(10px);
        height: rem(10px);
        transform: rotate(-45deg);
    }
}

.slick-prev {
    left: rem(1px);
    [dir="rtl"] & {
        left: auto;
        right: rem(1px);
    }
    &::before {
        transform: rotate(-135deg);
        [dir="rtl"] & {
            transform: rotate(45deg);
        }
    }
}

.slick-next {
    right: rem(1px);
    [dir="rtl"] & {
        left: rem(1px);
        right: auto;
    }
    &::before {
        transform: rotate(45deg);
        [dir="rtl"] & {
            transform: rotate(-135deg);
        }
    }
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: rem(30px);
}

.slick-dots {
    // position: absolute;
    bottom: 0;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    min-height: rem(22px);
    li {
        position: relative;
        display: inline-block;
        height: rem(22px);
        width: rem(22px);
        margin: 0 rem(5px);
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: $trans;
            display: block;
            height: rem(12px);
            width: rem(12px);
            line-height: 0;
            font-size: 0;
            color: $trans;
            padding: rem(5px);
            cursor: pointer;
            &:hover,
            &:focus {
                box-shadow: none;
                background-color: $trans;
                &::before {
                    opacity: $slick-opacity-on-hover;
                    background-color: $slick-dot-color-hover;
                }
            }
            &::before {
                content: "";
                position: absolute;
                top: rem(5px);
                left: rem(5px);
                border-radius: 50%;
                width: rem(8px);
                height: rem(8px);
                font-size: $slick-dot-size;
                line-height: rem(20px);
                text-align: center;
                color: $slick-dot-color;
                background-color: $slick-dot-color;
                opacity: $slick-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
        &.slick-active button::before {
            color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
        }
    }
}

.slick-dark-arrows {
    .slick-prev,
    .slick-next {
        height: rem(64px);
        width: rem(24px);
        background-color: $black;
        &:hover,
        &:focus {
            background: $black;
        }
        &::before {
            border-color: $white;
        }
    }
}
