nav#navigation {
    @extend .clearfix;
    left: -$mobile-menu-width;
    margin-top: 0;
    position: absolute;
    overflow-y: scroll;
    width: $mobile-menu-width;
    top: 0;
    &.active {
        left: 0;
        overflow: hidden;
    }
    @include respond($mobile-menu-breakpoint) {
        left: auto;
        margin-top: rem(15px); //25 on screen
        position: static;
        overflow-y: initial;
        -ms-overflow-style: none;
        width: 100%;
    }
}

.menu-toggle {
    background: $trans;
    border: none;
    border-radius: 0 !important;
    display: block;
    float: left;
    margin-left: 0.2em;
    padding: 0.5em;
    min-height: initial;
    line-height: initial;
    @include respond($mobile-menu-breakpoint) {
        display: none !important;
    }
}

button.mobile-only.clear-button-styles.menu-close::after {
    content: "";
    width: calc(100vw - 255px);
    height: 100vh;
    background: black;
    top: 0;
    position: fixed;
    left: 255px;
    opacity: .5;
    z-index: -1;
}

.menu-category {
    @include clearfix();
    list-style: none;
    margin: 0;
    padding: 0;
    @include respond($mobile-menu-breakpoint) {
        margin: 0 auto;
        text-align: center;
        min-width: $nav-min-width-desktop; //needed for docked nav in large vp
        width: 100%;
    }

    > li {
        list-style: none;
        @include respond($mobile-menu-breakpoint) {
            border: none;
            display: inline-block;
            float: left;
            padding-right: rem(35px);
            &:last-child {
                padding-right: 0;
            }
            &:hover > a {
                background-color: transparent;
            }
        }
    }
    a {
        display: block;
        padding: rem(13px) rem(6px) rem(13px) rem(25px);
        width: 100%;
        @include respond($mobile-menu-breakpoint) {
            width: auto;
            padding: 0;
        }
    }
}

.level-1 > li {
    @include clearfix();
    position: relative;
    // override background-color for small viewport
    background-color: transparent;
    border-bottom: $divider-mobile-nav;
    @include respond(desktop) {
        border: none;
        position: initial;
    }
    &.active {
        .menu-item-toggle svg {
            transform: rotate(-90deg);
        }
    }
    &.activeCat {
        @include respond($mobile-menu-breakpoint) {
            > a {
                color: $color-primary;
                &:hover {
                    color: $color-primary;
                }
            }
        }
    }
    &:hover {
        @include respond($mobile-menu-breakpoint) {
            transition: all .8s ease .5s;
            height: rem(60px);
            > a {
                color: $link-nav-color-hover;
            }
            .level-2-wrapper::before {
                visibility: visible;
                display: block;
            }
        }
    }
    .menu-item-toggle {
        cursor: pointer;
        float: right;
        line-height: 1.2;
        padding: rem(13px) rem(7px);
        text-align: center;
        position: absolute;
        left: 0;
        top: 0;
        height: 40px;
        width: 100%;
        text-align: right;
        padding-right: 15px;
        @include respond(large) {
            .icon {
                width: 14px;
                height: 14px;
            }
        }
        @include respond($mobile-menu-breakpoint) {
            display: none;
        }
        &-long {
            margin-bottom: rem(36px);
        }
    }
    a {
        @include nav-link;
        opacity: 1;
        @include respond($mobile-menu-breakpoint) {
            &:hover {
                background-color: transparent;
            }
        }
    }
    > a {
        float: left;
        text-transform: $h6-text-transform;
        &.has-sub-menu {
            width: auto;
        }
        @include respond($mobile-menu-breakpoint) {
            float: none;
            width: 100%;
            &.has-sub-menu {
                width: 100%;
            }
        }
    }
    /*.age-range-long {
        padding: 0 0 rem(13px) rem(25px);
    }*/
}

.age-range {
    float: left;
    @include info-mobile;
    @include line-height(14px);
    padding: 0 0 0 rem(25px);
    width: auto;
    &.age-range-long {
        padding: 0 0 0 rem(25px);
    }
    @include respond($mobile-menu-breakpoint) {
        background: $color-nav-age-flag;
        color: $badge-flag-color;
        float: none;
        height: rem(20px);
        @include info-desktop;
        @include line-height(22px);
        text-align: center;
        width: auto;
        white-space: nowrap;
        padding: 0 rem(5px);
        position: relative;
        top: 5px;
        visibility: visible !important;
        border: none;
        z-index: 9;
        &.age-range-long {
            padding: 0 rem(5px);
        }
        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
            border: 10px solid $color-nav-age-flag;
            bottom: 0;
            top: 0;
        }
        &::before {
            left: -16px;
            border-right-width: 15px;
            border-left-color: $trans;
            border-left-width: 4px;
        }
        &::after {
            right: -16px;
            border-left-width: 15px;
            border-right-color: $trans;
            border-right-width: 4px;
        }
    }
}

.menu-badge {
    @include h6-mobile;
    color: $color-menu-badge;
    @include respond($mobile-menu-breakpoint) {
        @include h6-desktop;
    }
}

.level-2-wrapper {
    @include respond($mobile-menu-breakpoint) {
        @include full-bleed-width;
        position: absolute;
        left: 0;
        z-index: 10;
        background: $color-body-bg;
        box-shadow: $box-shadow-nav;
        &::before {
            content: "";
            width: 100%;
            border-top: $divider-primary;
            display: none;
            position: relative;
            top: 15px;
            visibility: visible;
        }
    }
}

.level-2 {
    clear: left;
    display: none;
    text-align: left;
    .menu-group {
        @include respond($mobile-menu-breakpoint) {
            &.column4 {
                .menu-group-title {
                    color: $color-menu-badge;
                }
            }
        }
    }
    .menu-group-title {
        @include h6-mobile;
        @include respond(large-down) {
            cursor: pointer;
            @include line-height(30px);
            height: 30px;
            margin: 0;
            &::after {
                content: "+";
                display: inline-block;
                float: right;
                color: $input-select-label-color-hover;
                font-family: $h3-font;
                @include font-size($h3-font-size-mobile);
                margin-right: rem(15px);
                right: 10px;
                margin-top: -2px;
            }
            &.expanded::after {
                content: "-";
                margin-right: rem(17px);
            }
        }
    }
    ul {
        @extend .clearfix;
        padding: 0 0 0 rem(10px);
        display: none;
        list-style: none;
        li a {
            @include info-mobile;
            padding: rem(5px) 0;
        }
        li:hover {
            a {
                color: $color-primary;
            }
        }
        @include respond(large-down) {
            &.expanded {
                display: block;
            }
            li:last-of-type {
                margin-bottom: rem(10px);
            }
        }
    }
    .level-1 > .active & {
        display: block;
        @include respond(large-down) {
            padding: 0;
            clear: both;
        }
        .menu-groupings {
            border-top: $divider-mobile-nav;
            padding: rem(17px) 0 rem(17px) rem(40px);
        }
    }
    @include respond($mobile-menu-breakpoint) {
        padding: 0;
        max-width: $max-content-breakpoint;
        margin: 0 auto;
        z-index: 10;
        .level-1 li:hover & {
            display: block;
            transition: all .8s ease .5s;
        }
        .menu-groupings {
            padding: 0;
            margin-top: rem(30px); // reduced in CR10
            border: none;
            min-width: 70%;
            float: left;
        }
        .menu-group {
            box-sizing: border-box;
            width: 25%;
            float: left;
            margin-bottom: rem(20px);
            &.column4 {
                .menu-group-title {
                    color: $color-menu-badge;
                }
            }
        }
        .menu-group-title {
            @include h6-desktop;
        }
        ul {
            padding: 0;
            display: block;
            > li {
                float: none;
                > a {
                    @include p-desktop;
                    text-transform: none;
                    padding: rem(11px) 0 0 0;
                    &:hover {
                        text-decoration: none;
                        color: $link-base-hover-color;
                    }
                }
            }
        }
        .view-all-categories {
            text-decoration: underline;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    @include respond($large-breakpoint, $max-content-breakpoint) {
        padding: 0 rem(15px);
    }
    .header-menu-banner > div {
        display: none;
        .extendContentColumn {
            float:left;
            max-width: 270px;
            margin-left: 10px;
        }
        .cta-link{
            margin-top: 0.625rem;
            display: block;
            max-width: 270px;
            img {
                max-width: 100%;
            }
            &::after {
                content: url("../images/svg-icons/Arrow_inline.svg");
                display: inline-block;
                margin-left: rem(5px);
                width: rem(13px);
                height: rem(13px);
                position: relative;
                top: rem(2px);
            }
        }
        a {
            margin: 0;
            img {
                max-width: 100%;
            }
        }
        @include respond($mobile-menu-breakpoint) {
            display: block;
        }
    }
}



// extended content layout
.level-2.extendContent {
    @include respond($mobile-menu-breakpoint) {
        .menu-groupings {
            min-width: 16%;
            width: 16% !important;
        }
        .column1 {
            width: 100%;
        }
        .column2,
        .column3,
        .column4 {
            display: none;
        }
        // the extended content
        .header-menu-banner > div {
            width: 84%;
            max-width: 84%;
            float: left;
            text-align: center;
            .extendContentColumn {
                width: 23.5%;
                margin-left: 1.5%;
                float: left;
                .cta-link {
                    margin-top: rem(10px);
                }
            }
        }
    }
}

//left nav fix
@include respond($large-breakpoint, $max-content-breakpoint) {
    .refinement .categorymenugroup .level-2 {
        padding: 0;
    }
}

/*.level-1 li .level-2.open {
    display: block;
}*/
.level-1 li.show .level-2 {
    display: block;
}
/*.level-1 li.show .has-sub-menu {
    outline: 1px solid $dark-grey;
}*/

.subcategory font {
    pointer-events: none !important;
}

.nav-link {
    text-transform: uppercase;

    @include fontsize(14px, 20px);
}

@include media-breakpoint-up(md) {
    .navbar-expand-md .navbar-nav .dropdown-menu {
        margin-top: 0;
        padding-top: 0;
    }
}
.nav-model-opened{
    overflow: hidden;
}
