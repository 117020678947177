.promotions span.tooltip {
    position: relative;
    opacity: 1;
    display: inline-block;
}


.tooltip-text {
    @include tooltip-text;
    padding-right: rem(5px);
}
.tooltip .icon {
    position: relative;
    display: inline-block;
    width: rem(12px);
    height: rem(12px);
    &.product-tile {
        cursor: pointer;
        display: block;
    }
}

@include media-breakpoint-down(md) {
    .form-field-tooltip {
        float: none;
        margin: 0 0 10px 5px;
        width: auto;
    }
}


.tooltip-content {
    display: none;
}

.custom-tooltip {
    background: $white;
    border: $border-width $border-style $tooltip-border-color;
    text-align: center;
    max-width: $tooltip-maxwidth-mobile;
    p {
        @include alt-p;
    }
    @include respond(large-up) {
        max-width: $tooltip-maxwidth-desktop;
    }
}

.qtip-content {
    text-align: center;
    padding: $tooltip-padding-mobile;
    @include respond(large-up) {
        padding: $tooltip-padding-desktop;
    }
}

.qtip-default .qtip-icon {
    background: $trans;
    border: 0;
    color: $trans;
    width: $tooltip-icon-size-mobile;
    @include respond(large-up) {
        width: $tooltip-icon-size-desktop;
    }
}
.qtip-icon {
    background: $trans;
    top: 10px;
    right: 10px;
    .ui-icon {
        background: $icon-close;
        background-repeat:  no-repeat;
        background-size: $tooltip-icon-size-mobile;
        width: $tooltip-icon-size-mobile;
        height: $tooltip-icon-size-mobile;
        @include respond(large-up) {
            background-size: $tooltip-icon-size-desktop;
        }
    }
}

.ui-tooltip-content {
    padding: 0.3em;
    .recommendation-tooltip-header {
        padding: 0.5em;
        .product-price {
            .price-standard {
                color: $dark-grey;
                text-decoration: line-through;
            }
        }
    }
    .recommendation-tooltip-description,
    .recommendation-tooltip-attributes {
        padding: 0.8em;
    }
    .shipping-method-cost,
    .surcharge-product,
    .promo {
        text-align: right;
        padding-bottom: .3rem;
    }
    .shippingtotal {
        clear: right;
        text-align: right;
        margin-top: .3rem;
        .value {
            border-top: 1px solid $dark-grey;
            padding-top: .3rem;
        }
    }
}

.product-tile .promotions .tooltip {
    display: none;
}
