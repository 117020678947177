@import "amplience_tabs";
@import "amplience_carousel";

.amplienceContent {
    position: relative;
    .section-title {
        margin-bottom: rem(20px);
        @include respond(large-up) {
            margin-bottom: rem(35px);
        }

    }
    img,
    video {
        width: 100%;
    }
    img {
        height: auto;
    }
    .ytvideo-wrapper {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        .youtube-player {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.ampBannerGroup {
    // creates scrollable div in small viewports
    clear: both;
    &.overflow {
        .overflow-wrapper {
            width: 100%;
            @include respond(small-down) {
                overflow-x: scroll;
                padding: 0 rem(10px);
            }
            @include respond(large-up) {
                overflow: hidden;
            }
        }
        .scroll-wrapper {
            float: left;
            width: 150%;
            @include respond(large-up) {
                width: 100%;
            }
        }
        @include respond(small-down) {
            img {
                max-width: initial;
                max-width: auto; // IE
            }
        }
    }
    &.viewport-width {
        width: 100%;
    }
}

.amp-banner-container {
    position: relative;
    &.square,
    &.cta-3up,
    &.cta-2up,
    &.cta-4up,
    &.square-4up,
    &.rectangle {
        float: left;
        margin-right: 1%;
        position: relative;
        &:last-child {
            margin-right: 0;
        }
        .barCTA {
            display: none;
        }
    }
    // 4 across
    &.square-4up {
        width: calc(97% / 4);
        @include respond(small-down) {
            width: 100%;
            padding: 5%;
        }
        @include respond(large) {
            width: calc(98% / 2);
        }
        // tile with no image
        .content-wrapper {
            height: auto;
        }
        .bannerCTA {
            a.black-ribbon,
            a.navy-ribbon {
                min-width: 75%;
                top: -23px;
                @include respond(large-up) {
                    top: -31px;
                }
            }
        }
    }
    // cta 4up across
    &.cta-4up {
        width: calc(97% / 4);
        @include respond(small-down) {
            width: 100%;
            margin-top: 7px;
        }
        @include respond(large) {
            width: calc(98% / 2);
        }
        .content-wrapper {
            height: auto;
            .img-wrapper {
                @include respond(small-down) {
                    padding-left: 19%;
                    padding-right: 19%;
                    margin-top: 6px;
                }
            }
        }
        .bannerCTA {
            a.black-ribbon,
            a.navy-ribbon {
                min-width: 75%;
                top: -23px;
                @include respond(large-up) {
                    top: -31px;
                }
            }
        }
    }
    // 3 across
    &.square {
        width: 32%;
        @include respond(small-down) {
            width: 100%;
            padding: 5%;
        }
        margin-right: 2%;
        // tile with no image
        .content-wrapper {
            height: auto;
        }
        .bannerCTA {
            a.black-ribbon,
            a.navy-ribbon {
                min-width: 75%;
                top: -23px;
                @include respond(large-up) {
                    top: -31px;
                }
            }
        }
    }
    //cta 3up across
    &.cta-3up {
        width: 32%;
        margin-right: 2%;
        @include respond(small-down) {
            width: 100%;
            margin-top: 7px;
        }
        .content-wrapper {
            height: auto;
            .img-wrapper {
                @include respond(small-down) {
                    padding-left: 19%;
                    padding-right: 19%;
                    margin-top: 6px;
                }
            }
        }
        .bannerCTA {
            a.black-ribbon,
            a.navy-ribbon {
                min-width: 75%;
                top: -23px;
                @include respond(large-up) {
                    top: -31px;
                }
            }
        }
    }
    // 2 across
    &.rectangle {
        width: calc(98% / 2);
        @include respond(small-down) {
            width: 100%;
            padding: 5%;
        }
        // tile with no image
        .content-wrapper {
            height: auto;
            overflow: hidden; // needed to maintain aspect ratio
        }
        .bannerCTA {
            // rectangle override due to new padding
            .red-ribbon {
                top: -27px;
                @include respond(large) {
                    top: -21px;
                }
                @include respond(desktop) {
                    top: -22px;
                }
            }
            .black-ribbon {
                top: -23px;
                @include respond(large-up) {
                    top: -31px;
                }
            }
            .navy-ribbon {
                top: -23px;
                @include respond(large-up) {
                    top: -31px;
                }
            }
        }
    }
    //cta 2 across
    &.cta-2up {
        width: calc(98% / 2);
        @include respond(small-down) {
            width: 100%;
            margin-top: 7px;
        }
        .content-wrapper {
            height: auto;
            overflow: hidden; // needed to maintain aspect ratio
            .img-wrapper {
                @include respond(small-down) {
                    padding-left: 19%;
                    padding-right: 19%;
                    margin-top: 6px;
                }
            }
        }
        .bannerCTA {
            .red-ribbon {
                top: -27px;
                @include respond(large) {
                    top: -21px;
                }
                @include respond(desktop) {
                    top: -22px;
                }
            }
            .black-ribbon {
                top: -23px;
                @include respond(large-up) {
                    top: -31px;
                }
            }
            .navy-ribbon {
                top: -23px;
                @include respond(large-up) {
                    top: -31px;
                }
            }
        }
    }
    .content-wrapper {
        position: relative;
        a {
            text-decoration: none;
            img {
                cursor: pointer;
            }
        }
    }
    .amp-banner-text {
        box-sizing: border-box;
        padding: rem(10px);
        position: absolute;
        width: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
        &.bottom {
            top: 75%;
        }
        &.middle {
            top: 50%;
        }
        &.top {
            top: 25%;
        }
    }
    .section-title {
        .underlined-section-title {
            @include h2;
        }
        .c8-section-title {
            @include h3;
        }
    }
    .bannerCTA {
        text-align: center;
        margin-top: rem(10px);
        &.titlecase-link,
        &.uppercase-link {
            margin-top: rem(15px);
        }
        &.button,
        &.dark-blue-button,
        &.light-blue-button,
        &.stroke-button {
            margin-top: rem(20px);
            margin-bottom: 0;
        }
        &.red-ribbon {
            margin-top: 0;
            a {
                @include promo4;
                background: $badge-ribbon-bg-color;
                color: $badge-ribbon-color;
                height: $badge-ribbon-height-desktop;
                @include line-height($badge-ribbon-height-desktop);
                text-align: center;
                position: relative;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                text-decoration: none;
                position: relative;
                top: -24px;
                &::before,
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    border: 10.5px solid $badge-ribbon-bg-color;
                    bottom: 0;
                    @include respond(large-up) {
                        border-width: 21px;
                    }
                }
                &::before {
                    left: -15px;
                    border-right-width: 15px;
                    border-left-color: $trans;
                    border-left-width: 4px;
                }
                &::after {
                    right: -15px;
                    border-left-width: 15px;
                    border-right-color: $trans;
                    border-right-width: 4px;
                }
                @include respond(large-up) {
                    top: -18px;
                    &::before {
                        border-width: 21px;
                        border-left-width: 10px;
                    }
                    &::after {
                        border-width: 21px;
                        border-right-width: 10px;
                    }
                }
                span {
                    display: inline-block;
                    width: 75%;
                    white-space: nowrap;
                    position: relative;
                    z-index: 1;
                    &::after {
                        content: $icon-arrow-white;
                        display: inline-block;
                        margin-left: rem(5px);
                        width: rem(8px);
                        height: rem(8px);
                        position: relative;
                        top: 0;
                        @include respond(large-up) {
                            width: rem(13px);
                            height: rem(13px);
                            top: -1px;
                        }
                    }
                }
            }
        }
        &.black-ribbon,
        &.navy-ribbon {
            margin-top: 0;
            a {
                @include h6;
                background: $badge-ribbon-bg-color;
                color: $badge-ribbon-color;
                height: $badge-ribbon-height-mobile;
                @include line-height($badge-ribbon-height-mobile);
                text-align: center;
                position: relative;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                text-decoration: none;
                top: -24px;
                display: inline-block;
                min-width: 80%;
                @include respond(large-up) {
                    @include h5;
                    top: -31px;
                    min-width: 52%;
                    line-height: rem($badge-ribbon-height-desktop);
                    height: $badge-ribbon-height-desktop;
                }
            }
            span {
                display: inline-block;
                white-space: nowrap;
                position: relative;
                z-index: 1;
                @include line-height($badge-ribbon-height-mobile);
                @include respond(large-up) {
                    line-height: rem($badge-ribbon-height-desktop);
                }
            }
        }
    }
    // Single banner overrides
    &.bar {
        width: 100%;
        &.middle {
            top: 35%; // make room for CTA
        }
        .amp-banner-text {
            @include respond(large) {
                width: 50%;
            }
            @include respond(desktop) {
                width: 35%;
            }
            &.left {
                left: 25%;
            }
            &.right {
                left: 75%;
            }
        }
        .barCTA {
            .button {
                min-width: rem(270px);
                margin-top: rem(20px);
                @include respond(large-up) {
                    margin-top: rem(30px);
                }
            }
        }
    }
    &.image.bar .bannerCTA {
        margin-top: rem(20px);
        @include respond(large-up) {
            margin-top: rem(35px);
        }
    }
    .paragraph {
        @include p;
    }
    .alt-paragraph {
        @include alt-p;
    }
    .info-paragraph {
        @include info;
    }
}

.heroSlider {
    .amp-cms-video {
        position: relative;
        height: 0;
        overflow: hidden;
        max-width: 100vw;
        height: auto;
        iframe,
        video {
            width: 100vw;
            top: 0;
            left: 0;
            height: 100%;
        }
    }
}

.picture-poi-or-image-map {
    position: relative;
    width: 100%;
    display: block;
    img {
        width: 100%;
        position: relative;
    }
}
.popup-video-modal {
    .videoDiv {
        width: 100%;
        img {
            width: 100%;
        }
    }
}
.spot-map-poi {
    position: absolute;
    cursor: pointer;
    z-index: 1 !important;
}

.ui-dialog.popup-video-modal .ui-dialog-content {
    padding: 2%;
}
