.mobile-user-info {
    margin: 0;
    padding: 0;
    border-bottom: $divider-mobile-nav;
    .user-info-link {
        cursor: pointer;
        padding: rem(7px) 0 rem(7px) rem(25px);
        .icon {
            margin-right: rem(10px);
            &.Utility_Login {
                position: relative;
                top: -2px;
                width: 13px;
                height: 13px;
            }
        }
        a {
            @include h4;
            @include line-height(30px);
            text-decoration: none;
            text-transform: capitalize;
            @include respond(large) {
                @include h4-mobile;
                @include line-height(30px);
            }
        }
        .mobile-account-menu-toggle {
            cursor: pointer;
            float: right;
            line-height: 1.1;
            transition: background-color 0.3s;
            width: 1.25rem;
            margin: rem(5px) rem(7px) rem(13px) 0;
            &.active {
                .icon {
                    transform: rotate(-90deg);
                }
            }
        }
    }
    .user-panel {
        display: none;
        &.active {
            border-top: $divider-primary;
            display: block;
            margin-bottom: rem(16px);
        }
        .user-links {
            padding: rem(14px) rem(25px) 0 rem(25px);
            a {
                @include alt-p;
                text-decoration: none;
                @include line-height(34px);
                display: block;
                @include respond(large) {
                    @include font-size($paragraph-alt-font-size-mobile);
                    @include line-height(34px);
                    letter-spacing: $paragraph-alt-letter-spacing-mobile;
                }
            }
        }
    }
}

.header-usermenu-slot > div {
    background: $color-bg-light;
    margin: rem(6px) rem(25px) 0 rem(25px);
    padding: rem(20px);
    a {
        text-decoration: none;
    }
    img {
        float: left;
        width: 38%;
        margin-right: rem(10px);
        margin-top: rem(3px);
    }
    h4 {
        text-transform: uppercase;
        @include respond(large) {
            @include h4-mobile;
        }
    }
    p {
        margin: 0;
        @include respond(large) {
            @include font-size($paragraph-info-font-size-mobile);
            @include line-height($paragraph-info-line-height-mobile);
            letter-spacing: $paragraph-info-letter-spacing-mobile;
        }
    }
    @include respond(desktop) {
        margin: rem(15px) 0 0;
        h4 {
            @include font-size(14px); // not in style guide custom for this menu
            @include line-height(18px); // custom again
        }
    }
}

.menu-utility-user {
    margin: 0;
    padding: rem(10px) 0;
    @include respond($mobile-menu-breakpoint) {
        width: 100%;
        padding: rem(17px) rem(2px);
        overflow: hidden;
        .utility-links-left {
            float: left;
            width: 50%;
            .li.track-order {
                span::after {
                    border-color: $trans;
                    margin: 0;
                }
            }
        }
        // Desktop Specific Styles
        .utility-links-right {
            position: absolute;
            right: 23px;
            .user-info {
                float: left;
                margin: 0;
                padding: 0;
                a {
                    @include utility-link();
                    transition: color .5s ease;
                    // right divider line
                    span::after {
                        content: "";
                        border-right: $tab-horizontal-divider;
                        font-size: $tab-horizontal-divider-height-mobile;
                        margin-left: rem(12px);
                        margin-right: rem(12px);
                    }
                    &.user-account {
                        color: $link-utility-user-color;
                        text-transform: capitalize;
                    }
                }
                .icon {
                    position: relative;
                    margin-right: rem(2px); //8px on screen
                    &.Utility_Login {
                        width: 13px;
                        height: 13px;
                        top: -2px;
                    }
                }
            }
            //hide user info before it is moved
            & + .user-info {
                display: none;
            }
        }
    }

    // converted unordered list to divs used li class for ease
    .li {
        padding: rem(7px) rem(25px);
        @include respond($mobile-menu-breakpoint) {
            float: left;
            margin: 0;
            padding: 0;
        }

        .icon {
            width: 18px;
            height: 18px;
            margin-right: rem(10px);
            position: relative;
            @include respond($mobile-menu-breakpoint) {
                margin-right: rem(2px); //8px on screen
            }
            &.Utility_Store_Locator {
                @include respond($mobile-menu-breakpoint) {
                    width: 13px;
                    height: 17px;
                    top: -2px;
                }
            }
            &.Utility_Tracking {
                top: -2px;
                left: 1px;
            }
            &.Utility_Rewards {
                width: 15px; //appears smaller in comps
                margin-right: rem(13px);
                top: -2px;
                left: 2px;
                @include respond($mobile-menu-breakpoint) {
                    width: 13px;
                    margin-right: rem(7px);
                    height: 13px;
                    top: -2px;
                }
            }
            &.Heart {
                top: -1px;
                @include respond($mobile-menu-breakpoint) {
                    width: 15px;
                }
            }
            &.Utility_Login {
                @include respond($mobile-menu-breakpoint) {
                    width: 13px;
                    height: 13px;
                    top: -2px;
                }
            }
        }
        a:not(.buttonstyle),
        .user-account {
            @include alt-p();
            text-decoration: none;
            &:active {
                color: $red;
            }
            @include respond(large) {
                @include font-size($paragraph-alt-font-size-mobile);
                @include line-height($paragraph-alt-line-height-mobile);
            }
            @include respond($mobile-menu-breakpoint) {
                @include utility-link();
                transition: color .5s ease;
                // right divider line
                span::after {
                    content: "";
                    border-right: $tab-horizontal-divider;
                    font-size: $tab-horizontal-divider-height-mobile;
                    margin-left: rem(12px);
                    margin-right: rem(12px);
                }
            }
        }
    }
    .user-info-login {
        padding: rem(10px) rem(25px);
        text-align: center;
        a.button {
            width: 100%;
            @include respond(large) {
                font-size: rem($h6-font-size-mobile);
                letter-spacing: $h6-letter-spacing;
            }
        }
        @include respond(large) {
            .alt-p {
                @include font-size($paragraph-alt-font-size-mobile);
                @include line-height($paragraph-alt-line-height-mobile);
            }
        }
    }
    .user-info {
        // mobile user info appears above navigation
        display: none;
        @include respond($mobile-menu-breakpoint) {
            display: block;
            position: relative;
            &.active {
                .user-panel {
                    opacity: 1;
                    visibility: visible;
                    transition: opacity .5s ease;
                }
            }
        }

        .user-panel {
            box-sizing: content-box;
            //See https://code.google.com/p/chromium/issues/detail?can=2&q=max-content&colspec=ID%20Pri%20M%20Stars%20ReleaseBlock%20Cr%20Status%20Owner%20Summary%20OS%20Modified&id=522191&thanks=522191&ts=1439925077
            margin-top: .5rem;
            text-align: left;
            transition: all .3s ease;
            width: 10rem;
            //For IE
            width: max-content;
            // create element to fill space between link and menu and maintain hover
            &::before {
                height: rem(15px);
                content: "";
                display: block;
                background: $trans;
                position: relative;
                top: -32px;
            }
            h3 {
                display: none;
                margin: 0;
                padding-bottom: .7em;
                @include respond($mobile-menu-breakpoint) {
                    display: block;
                }
            }
            @include respond($mobile-menu-breakpoint) {
                background-color: $color-body-bg;
                border: $border-primary;
                margin-top: rem(15px);
                max-width: rem(230px);
                opacity: 0;
                padding: 1em;
                position: absolute;
                visibility: hidden;
                z-index: 20;
                transition: opacity .3s ease;
            }

            .user-links {
                padding-left: 1.5rem;
                text-align: center;
                @include respond($mobile-menu-breakpoint) {
                    padding-left: 0;
                }
            }
            a:not(.buttonstyle) {
                @include breadcrumb-link();
                display: block;
                padding: .5rem 0;
                @include respond($mobile-menu-breakpoint) {
                    padding: 0;
                    margin-bottom: .5rem;
                }
            }
        }
    }
}

.rewards-menu {
    .rewards-panel {
        display: none;
        @include respond($mobile-menu-breakpoint) {
            box-sizing: border-box;
            background-color: $color-body-bg;
            border: $border-primary;
            width: rem(400px);
            max-width: rem(400px);
            margin-top: rem(15px);
            opacity: 0;
            padding: rem(20px) rem(30px);
            position: absolute;
            right: -23px; //flush with right container
            text-align: center;
            transition: all .3s ease;
            visibility: hidden;
            z-index: 20;
            // create element to fill space between link and menu and maintain hover
            &::before {
                height: rem(15px);
                content: "";
                display: block;
                background: $trans;
                position: relative;
                top: -36px;
            }
            .promo3,
            .promo4 {
                color: $promo-color-dark;
                margin-bottom: 10px;
            }
            .rewards-menu-container {
                img {
                    width: rem(99px);
                }
            }
            .rewards-menu-container p {
                max-width: rem(255px);
                margin: 0 auto rem(10px);
            }
            // not logged in user
            .first-cta {
                margin-bottom: rem(10px);
            }
            a.cta-link {
                @include font-size($h5-font-size-desktop);
            }
            .earned-rewards {
                background: $color-bg-light;
                @include h5;
                line-height: 40px !important;
                margin-top: rem(17px); // 20px total on screen
                margin-bottom: rem(13px); // 15px total on screen
            }
            p.info {
                margin: rem(10px) 0 0;
            }
        }
    }
    &.active {
        @include respond($mobile-menu-breakpoint) {
            .rewards-panel {
                display: block;
                opacity: 1;
                visibility: visible;
                transition: all .3s ease;
            }
        }
    }
}

#rewards-takeover {
    display: none;
    @include respond(large-down) {
        background: $color-body-bg;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: rem(12px) rem(10px);
        z-index: 1001;
        &.active {
            display: block;
        }
        .Close {
            float: right;
            width: 15px;
            height: 15px;
            position: relative;
            top: 14px;
            right: 5px;
        }
        .rewards-panel {
            text-align: center;
            margin-top: rem(40px);
        }
        .promo3 {
            @include promo2;
            color: $promo-color-dark;
        }
        .rewards-menu-container p {
            max-width: rem(255px);
            margin: 0 auto rem(10px);
        }
        // not logged in user
        .first-cta {
            margin-bottom: rem(10px);
        }
        a.cta-link {
            @include font-size($h5-font-size-desktop);
        }
        .earned-rewards {
            background: $color-bg-light;
            @include h5;
            line-height: 40px !important;
            margin-top: rem(17px); // 20px total on screen
            margin-bottom: rem(13px); // 15px total on screen
        }
        p.info {
            margin: rem(10px) 0 0;
        }
    }
}
