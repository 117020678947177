#addtocart-modal {
    display: none;
    @include respond($mobile-menu-breakpoint) {
        top: rem(11px);
        right: 0;
    }
}
.mini-cart-content-modal,
.mini-cart-content-modal-PS {
    display: none;
}
div.addToCartDialog {
    width: 80%;
    .ui-dialog-title {
        display: none;
    }
    // title bar in dialog
    .ui-dialog-titlebar {
        button span {
            width: rem(15px);
            height: rem(15px);
            @include respond(large-up) {
                width: rem(25px);
                height: rem(25px);
            }
        }
        button.ui-dialog-titlebar-close {
            z-index: 99;
            top: rem(5px);
            height: rem(15px);
            margin: 0;
            padding: 0;
            right: rem(7px);
            top: rem(7px);
            width: rem(15px);
            @include respond(large-up) {
                top: rem(20px);
                right: rem(25px);
                width: rem(25px);
                height: rem(25px);
            }

        }
    }

    .mini-cart-content-modal,
    .mini-cart-content-modal-PS {
        display: none;
        right: 0;
        top: 0;
        z-index: 20;
        position: relative;
        padding-top: 0;
        .addtocart-modal-div {
            width: 90%;
            left: 0;
            margin-bottom: rem(20px);
        }
        .add-to-cart-modal-top {
            margin: 0 auto;
            width: 100%;
            padding: rem(30px) 0 rem(10px) 0;
            text-align: center;
            @include respond(large-up) {
                padding: rem(20px) 0 rem(15px) 0;
                width: auto;
            }
            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;
                display: inline-table;
            }
            li {
                padding: 0 rem(5px);
                float: left;
                @include respond(large-up) {
                    padding: rem(3px) rem(5px) rem(3px) 0;
                }
                h3 {
                    float: left;
                    line-height: rem(18px);
                    @include respond(large-up) {
                        line-height: rem(25px);
                    }
                }
                @include respond(large-up) {
                    padding: 0 rem(15px);
                }
                &:first-child {
                    border-right: $border-primary;
                }
            }
        }
        .add-to-cart-modal-recommendations {
            border-top: $border-primary;
            clear: both;
            padding-top: 0;
            position: relative;

            .recommendations-header,
            .carousel-header {
                @include h3();
                margin-top: rem(30px);
                @include respond(large-up) {
                    @include h3();
                    margin-top: rem(20px);
                }
                .section-title-line {
                    margin-top: rem(5px) auto 0;
                    max-width: $section-title-border-width-mobile;
                    border-bottom: $section-title-border-mobile;
                    @include respond(large-up) {
                        margin-top: rem(5px);
                        max-width: $section-title-border-width-desktop;
                        border-bottom: $section-title-border-desktop;
                    }
                }
                .quickview-link {
                    display: none;
                }
            }
            .product-swatches {
                display: none;
            }
            //recommendation part in dialog
            .carousel-recommendations,
            .tiles-container {
                padding: rem(25px) rem(5px) rem(10px) rem(5px);
                @include respond(large-up) {
                    padding: rem(20px) rem(15px) rem(30px) rem(15px);
                }
                button {
                    @include respond(large-up) {
                        display: none;
                    }
                }
                .Arrow_small {
                    width: rem(30px);
                    height: rem(30px);
                    transform: rotate(180deg);
                }
                .Arrow_small.down-arrow {
                    transform: rotate(0deg);
                }
                .recommendation-item {
                    padding-right: rem(5px);
                    padding-left: rem(5px);
                    @include respond(large-up) {
                        padding-right: rem(17px);
                    }
                }
                .recommendation_image {
                    width: 99%;
                    img {
                        width: 98%;
                        margin: 0 auto;
                        @include respond(large-up) {
                            min-width: rem(178px);
                            width: 99%;
                        }
                    }
                    @include respond(large-up) {
                        width: rem(178px);
                    }
                }
                .product-price {
                    @include respond(large-down) {
                        margin-top: rem(5px);
                    }
                }
                .slider-next,
                .slider-prev {
                    top: 25%;
                }
                .slider-next {
                    right: rem(5px);
                }
                .slider-prev {
                    left: rem(5px);
                }
            }
        }

    }
    .mini-cart-content {
        display: block;
        position: relative;
    }
    .ui-widget-header {
        height: 0;
        padding: 0;
    }
    .desktoptitle {
        display: none;
        @include respond(large-up) {
            display: inline-block;
        }
    }
    .mobiletitle {
        display: inline-block;
        @include respond(large-up) {
            display: none;
        }
    }
    .add_to_cart-content {
        clear: both;
        width: 100%;
        border: 0;
        padding: 0 15px;
        box-sizing: border-box;
        @include respond(large-up) {
            border-top: $border-width $border-style $input-border-color;
        }

        .caret {
            position: relative;
            margin-top: rem(-1px);
            left: 44%;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                border-top: 13px $border-style $input-border-color;
                border-left: 34px $border-style transparent;
                border-right: 34px $border-style transparent;
            }
            &::after {
                content: "";
                position: absolute;
                left: 2px;
                border-top: 12px $border-style white;
                border-left: 32px $border-style transparent;
                border-right: 32px $border-style transparent;
            }
            @include respond(large-up) {
                display: none;
            }
        }
        .light-box-products {
            float: left;
            width: 57%;
            border-bottom: 0;
            display: none;
            padding: 0;
            .mini-cart-product {
                padding: 0;
                margin-top: 0;
                @include respond(large-up) {
                    margin: rem(25px) 0;
                    padding: 0;
                }
            }
            @include respond(large-up) {
                display: block;
            }
        }
        .mini-cart-image {
            width: rem(133px);
            max-height: rem(180px);
            @include respond(large-up) {
                width: rem(178px);
                height: rem(188px);
                max-height: rem(188px);
            }
            img {
                width: rem(133px);
                max-height: rem(133px);
                @include respond(large-up) {
                    width: rem(178px);
                    max-height: rem(188px);
                    max-width: 100%;
                }
            }
        }
        .mini-cart-pricing {
            display: none;
        }
        .mini-cart-pricing-modal {
            display: block;
            margin-bottom: rem(15px);
            .mini-cart-price-sales,
            .mini-cart-price,
            .mini-cart-price-standard {
                display: inline-block;
                @include h4();

            }
            .mini-cart-price-standard {
                color: $addtocart-dialog-product-price-original-fontcolor;
            }
        }
        .mini-cart-totals {
            float: left;
            width: 99%;
            padding: 0 rem($button-small-side-padding);
            border: 0;
            box-sizing: border-box;
            margin: 0;
            padding: 0 rem($button-small-side-padding);
            @include respond(large-up) {
                float: left;
                width: 34%;
                padding: rem(25px) 0 0 0;
                border: 0;
            }

            a {
                margin: 0 auto;
            }
            .close-modal-div {
                margin: rem(14px) 0 rem(17px) 0;
                @include respond(large-up) {
                    margin-top: rem(20px);
                }
            }
        }
        .mini-cart-name-attr-container {
            text-align: left;
            margin: 0 0 0 rem(20px);
            max-width: inherit;
            width: auto;
        }
        .mini-cart-name {
            margin-bottom: rem(15px);
            max-width: rem(220px);
            a,
            span {
                @include h4();
            }
        }
        .productid {
            display: none;
        }
    }
    .ui-dialog-content {
        padding: 0;
    }
    .mini-cart {
        .bonus-discount-container {
            display: none;
        }
    }
}
