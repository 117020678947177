.mini-cart {
    position: absolute;
    right: 10px;
    top: 17px;
    z-index: 20;
    @include respond($mobile-menu-breakpoint) {
        top: rem(11px);
        right: 0;
    }
    .mini-cart-name-attr-container {
        .productid {
            display: none;
        }
    }
}

.mini-cart-total {
    position: relative;
    margin-bottom: rem(15px); // to not lose mouseover event
    .mini-cart-link {
        line-height: initial;
        line-height: auto; // IE
        .icon {
            position: relative;
            top: -4px;
        }
        .minicart-quantity {
            @include h7;
            color: $minicart-qty-color;
            position: absolute;
            right: $minicart-qty-right-position;
            text-align: center;
            top: $minicart-qty-top-position-mobile;
            width: $minicart-qty-width-mobile;
            @include respond(large) {
                @include h7-mobile;
            }
            @include respond(desktop) {
                width: $minicart-qty-width;
                top: $minicart-qty-top-position-desktop;
                right: 0;
            }
        }
    }
    .mini-cart-empty {
        color: $color-primary;
        transition: color 500ms ease;
        &:hover {
            text-decoration: none;
        }
        .minicart-quantity {
            background-color: $white;
            border: $border-dark;
            color: $color-primary;
        }
    }
}

.mini-cart-content {
    background-color: $white;
    border: $border-secondary;
    display: none;
    position: absolute;
    right: 0;
    top: 2.5em;
    width: 392px;
    @include respond(small-down) {
        max-width: 220px;
    }
    .mCustomScrollBox {
        z-index: 1;
    }

    .mCSB_scrollTools {
        background: $light-grey;
        opacity: 1;
        right: 14px;
        height: calc(100% - 30px);
        top: rem(15px);
        width: rem(8px);
        @include respond(large-up) {
            width: rem(10px);
            right: rem(2px);
        }

        .mCSB_dragger {
            .mCSB_dragger_bar {
                width: 100%;
                background: #d3d3d3;
                border-radius: 4px;
                @include respond(large-up) {
                    border-radius: 5px;
                }
            }
        }

        .mCSB_draggerRail {
            background: none;
            width: 100%;
        }
    }
}

.mini-cart-header {
    padding: 1em 1em 0.5em;
}

.mini-cart-products {
    max-height: rem(350px);
    padding: 0.5em;
    overflow: auto;
    border-bottom: $border-primary;
    @include respond(small-down) {
        max-height: 100%;
    }

    @include respond(large) {
        max-height: 100%;
    }
}

.mini-cart-product {
    border-bottom: $border-primary;
    overflow: hidden;
    margin: 0 rem(25px) rem(5px) rem(13px);
    padding: rem(12px) 0 rem(12px) rem(5px);
    position: relative;
    &:last-of-type {
        border: none;
    }
    &.collapsed {
        .mini-cart-name,
        .mini-cart-attributes,
        .mini-cart-pricing,
        .product-availability-list {
            width: 90%;
        }
        .mini-cart-image {
            display: none;
        }
    }
    .mini-cart-toggle {
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
    }
}

.mini-cart-attributes {
    font-size: rem(14px);
    .attribute {
        margin: rem(6px) 0;
        .label {
            text-transform: capitalize;
        }
    }
}

.mini-cart-image {
    float: left;
    width: 24%;
    img {
        max-width: 90%;
    }
}

.mini-cart-name {
    @include h7();
    text-transform: none;
    a {
        text-decoration: none;
        letter-spacing: rem(.8px);
        @include h7();
    }
}

.mini-cart-pricing {
    float: right;
    margin: rem(3px) 0;
    font-size: rem(14px);
    text-align: right;
    &.noImage {
        margin-top: rem(3px) !important;
    }
    .rewardlabel {
        display: inline;
        float: left;
    }
    .rewardvalue {
        display: inline;
    }
}

.mini-cart-gymbukcs {
    margin: 0;
    display: block;
    width: 75%;
}
.mini-cart-totals {
    margin: 0 rem(21px);
    padding-top: rem(15px);
}

.mini-cart-subtotals {
    padding-top: .5em;
    @include h5();
    .label {
        margin: 0 .5rem;
        text-transform: uppercase;
        letter-spacing: 1.1px;
        @include h5();
    }
    .value {
        margin-right: .5rem;
        float: right;
        @include h5();
    }
}

.mini-cart-slot > div {
    background: none repeat scroll 0 0 $minicart-slot-background;
    margin: 0;
    padding: rem(17px) rem(42px) rem(17px) rem(47px);
    @include font-size(12px);
    svg {
        width: 25px;
        height: 25px;
    }
}

.mini-cart-link-checkout {
    clear: both;
    display: block;
    margin: 1em 0;
    text-align: center;
}

.checkout-mini-cart {
    max-height: 330px;
    overflow: auto;
    padding-right: rem(10px); // because of scrollbar
}

.mini-cart-promo {
    padding-left: rem(8px);
    letter-spacing: rem(.6px);
}

.mini-cart-promotion > div {
    margin: rem(10px) auto 0;
    width: 90%;
    border-bottom: $divider-primary;
    .approaching-discount {
        margin: 0 auto;
        width: 80%;
    }
    .cart-promo-approaching {
        color: $color-approaching-promo;
        font-size: rem(12px);
        letter-spacing: rem(.8px);
        display: inline-block;
        float: left;
        width: 85%;
    }
    .cart-promo {
        padding: 0 rem(12px) 0 rem(3px);
        margin: 0 rem(15px) rem(15px) rem(4px);
    }
    .content-asset {
        display: inline;
    }
    .icon {
        width: 22px;
        height: 22px;
        float: left;
        &.Utility_Tracking {
            position: relative;
            top: -6px;
        }
    }
}

.mini-cart-name-attr-container {
    float: left;
    margin: rem(4px) rem(3px);
    max-width: rem(180px);
    width: 75%;
}

.discounts-applied {
    color: $color-sales-price;
}

.mini-cart-price-sales {
    color: $color-sales-price;
}

.mini-cart-price-standard {
    text-decoration: line-through;
}

.mini-cart-price-sales,
.mini-cart-price-standard {
    display: block;
    text-align: right;
}

.mini-cart-quantity {
    font-size: rem(14px);
}
