.size-chart-modal {
    overflow-y: auto;
    max-height: 100vh;
    .modal-dialog {
        max-width: 950px;
    }

    .modal-body {
        padding: 0;

        @include media-breakpoint-up(md) {
            padding: 10px 75px 95px;
        }
    }

    .close {
        opacity: 1;
    }

    .size-close {
        background-image: $close-svg;
        background-position: 0 0;
        height: 18px;
        width: 18px;
        display: inline-block;

        @include media-breakpoint-up(md) {
            height: 30px;
            width: 30px;
        }
    }
}

.size-chart-tips {
    border: 0;
    padding: 0;

    @include media-breakpoint-up(md) {
        padding: 40px 0 0;
        border: 1px solid $disabled-grey;
    }
}

.size-chart .section-title {
    padding-bottom: 10px;
    text-align: center;
    margin: 0 auto;
    @include media-breakpoint-up(md) {
        margin: rem(25px) auto rem(20px);
    }
}

.size-chart .normal {
    text-decoration: underline !important;
    font-size: rem(12px) !important;
}

.size-chart .tabbedContent .tab-wrapper {
    margin: 0 rem(10px);
}

.size-chart .tabbedContent .tab-wrapper button {
    background: $white;
    border: 0;
    text-transform: uppercase;
}

.size-chart .tabbedContent .tabs li.active span {
    display: inline-block;
    @include accent-border;
}

.size-chart .tabbedContent .tabs {
    text-align: center;
    height: auto;
    padding-left: 0;
}

.tabbedContent .tabs li:after {
    font-size: 10px;
    content: "";
    border-right: 1px solid $disabled-grey;
    font-size: 8.5px;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
}

.size-chart .tabbedContent .tabs li {
    display: inline;
    float: none;
}

.size-chart h3 {
    margin-left: 10px;
    padding-right: 45px;
    text-transform: none;
    letter-spacing: 0;
    text-align: left;
    @include media-breakpoint-up(md) {
        margin-left: 40px;
    }
}

.size-chart-mobile {
    display: block;
    @include media-breakpoint-up(md) {
        display: none;
    }
    img {
        max-width: 400px;
        width: 100%;
        margin: 10px auto 0;
        display: block;
    }
}

.size-chart-desktop {
    display: none;
    @include media-breakpoint-up(md) {
        display: block;
    }
    img {
        max-width: 800px;
        width: 100%;
        margin: 0 auto;
        display: block;
    }
}

.size-chart .accordion .drawerContent {
    padding: 0;
}

.size-chart .accordion {
    border-top: $divider-primary;
    margin-top: 30px;
}

.size-chart-table {
    border: none;
    color: $color-primary;
    text-align: left;
}

.size-chart-table th {
    background: $color-table-header;
    color: $color-body-bg;
    text-align: left;
    @include h7;
    text-transform: uppercase;
    padding: 10px;
    @include media-breakpoint-up(md) {
        padding: 10px 0 10px 20px;
    }
}

.size-chart-table td {
    padding: rem(10px) rem(5px);
    @include info;
    line-height: normal;
    @include media-breakpoint-up(md) {
        padding: 10px 0 10px 20px;
        color: $color-primary;
        vertical-align: middle;
    }
}

.size-chart-table tr:nth-child(even) {
    background: $color-body-bg;
    border-bottom: $divider-primary;
}

.size-chart-table tr:nth-child(odd) {
    background: $color-bg-light;
    border-bottom: $divider-primary;
}

.size-chart-table tr:last-child {
    border-bottom: none;
}

// needed for iOS click event
.size-chart .accordion .drawerHeading {
    cursor: pointer;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 1.25rem;
    border-bottom: 1px solid $disabled-grey;
    position: relative;
    margin-bottom: 0;
}

@include media-breakpoint-up(md) {
    .size-chart .accordion .drawerHeading {
        padding: 1.25rem 0;
    }
}

.size-chart .accordion .drawerHeading .icon {
    top: -21px;
    right: 10px;
    @include media-breakpoint-up(md) {
        margin-top: -9px;
        margin-right: 40px;
    }

}

.size-chart .accordion .drawerHeading.drawerActive .icon {
    width: 18px;
    height: 18px;
    right: 10px;
    @include media-breakpoint-up(md) {
        width: 10px;
        height: 10px;
    }
}

.size-chart .tabbedContent-inner {
    text-align: left;
    height: auto;
}

.size-chart .tabbedContent-inner .tab-wrapper {
    display: block;
    float: left;
    position: relative;
    left: 10px;
    text-align: left;
    margin-bottom: 10px;
    @include media-breakpoint-up(md) {
        left: 0%;
        margin-bottom: 0;
    }
}

.size-chart .tabbedContent-inner .tabs {
    left: 0%;
    @include media-breakpoint-up(md) {
        margin: 0 0 10px 30px;
    }
}


.tips-wrapper {
    padding: 0 rem(10px) rem(10px);
    text-align: left;
    @include media-breakpoint-up(md) {
        p {
            max-width: 550px;
            margin: 30px auto;
            text-align: center;
            line-height: 27px;
        }
    }
}

// accordion drawers
.accordion {
    .drawerHeading {
        padding: rem(20px) 0;
        border-bottom: $divider-primary;
        position: relative;
        @include respond(large-up) {
            padding: rem(30px) 0;
        }
        h6 {
            width: 90%;
        }
        &.drawerActive {
            border-bottom: none;
            .icon {
                transform: rotate(-90deg);
                right: 1px;
            }
        }
    }
    .drawerContent {
        display: none;
        clear: both;
        padding: 0 rem(25px) rem(25px);
        overflow: hidden;
        border-bottom: $divider-primary;
    }
}